import React from "react";
import { Grid } from "@material-ui/core";

export default function Unauthorized(props) {
  return (
    <Grid className="mainContainer">
      <Grid>
        <h1>You are not authorized on this app</h1>
      </Grid>
    </Grid>
  );
}
