import React, { useState, useEffect } from "react";

import { useSelector, useDispatch } from "react-redux";
import { openErrorToast, updateAccessor, updateClient } from "./redux/actions";

import LoginPage from "./pages/LoginPage";
import ErrorPage from "./pages/ErrorPage";
import ProjectsPage from "./pages/ProjectsPage";
import Dashboard from "./pages/Dashboard";
import ProjectPage from "./pages/ProjectPage";
import NewProjectPage from "./pages/NewProjectPage";
import OpsCenter from "./pages/OpsCenter";
import ClientAdmin from "./pages/ClientAdmin";
import Unauthorized from "./pages/Unauthorized";

import SVGLoading from "./components/SVGLoading";

import { getAccount, verifyAccount, setLatestAccessTime } from "./Api";
import pages from "./components/pagesList";
import Toast from "./components/Toast";

import firebase from "firebase/app";
import "firebase/auth";

import { Switch, Route, withRouter, Redirect } from "react-router-dom";
import { parse } from "querystring";

import { Grid } from "@material-ui/core";

import {
  responsiveFontSizes,
  createMuiTheme,
  ThemeProvider
} from "@material-ui/core/styles";

import "./App.css";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import { makeStyles } from "@material-ui/styles";

const theme = responsiveFontSizes(
  createMuiTheme({
    status: {
      error: {
        main: "#FF5252"
      },
      requested: {
        main: "#FF9800"
      },
      accepted: {
        main: "#FFCF00"
      },
      completed: {
        main: "#4CAF50"
      }
    },
    typography: {
      fontFamily: "Ubuntu",
      useNextVariants: true,
      h1: {
        fontSize: "59px"
      },
      h2: {
        fontSize: "37px"
      },
      h3: {
        fontSize: "23px"
      },
      body1: {
        fontSize: "14px"
      },
      body2: {
        fontSize: "16px"
      },
      caption: {
        fontSize: "10px"
      }
    },
    palette: {
      primary: {
        main: "#439fd9",
        contrastText: "#FFFFFF"
      },
      secondary: {
        main: "#A5a5a5"
      },
      // Todo Set secondary and error colours
      error: {
        main: "#FF5252"
      },
      warning: {
        main: "#FF5252"
      },
      info: {
        main: "#A5a5a5"
      },
      success: {
        main: "#A1F4A5"
      }
    }
  })
);

const useStyles = makeStyles(theme => ({
  loadingDiv: {
    height: "100%"
  }
}));

function App(props) {
  const [loading, setLoading] = useState(true);
  const [online, setOnline] = useState(true);

  const dispatch = useDispatch();

  const updateNetworkStatus = async () => {
    setOnline(window.navigator.onLine);
  };
  const listenToNetworkChanges = () => {
    window.addEventListener("online", updateNetworkStatus);
    window.addEventListener("offline", updateNetworkStatus);
  };

  const listenToAuthStateChanges = async () => {
    firebase.auth().onAuthStateChanged(async user => {
      // to handle Redirect and URL Parameters
      let { redirect, ...propObject } = parse(props.location.search.slice(1));
      if (propObject.error) dispatch(openErrorToast(propObject.error));

      if (user) {
        const { accounts } = await getAccount(user.email);

        if (accounts) {
          const account = accounts[0];
          if (!account.verifiedFlag)
            verifyAccount(account.id).catch(error =>
              dispatch(openErrorToast("Not able to verify account"))
            ); // Verify if unverified
          if (account) dispatch(updateAccessor(account)); // update redux
          if (account.clientId) dispatch(updateClient(account.clientId)); // update redux
          if (account.id) setLatestAccessTime(account.id);
        } else {
          props.history.push("/unauthorized");
        }
      }
      setLoading(false);
      if (user && window.location.pathname === "/login")
        props.history.push(
          `/${redirect || ""}${
            Object.entries(propObject).length > 0
              ? `?${Object.entries(propObject)
                  .map(([key, value]) => `${key}=${value}`)
                  .join("&")}`
              : ``
          }`
        );
      // if in login page redirect the user to the redirect OR home page
      else if (!user && window.location.pathname !== "/login")
        props.history.push("/login");
      // if in any other page redirect the user to the login page
    });
  };

  // Run hook once
  useEffect(() => {
    listenToAuthStateChanges();
    listenToNetworkChanges();
  }, []);

  const classes = useStyles();

  return (
    <ThemeProvider theme={theme}>
      {loading ? (
        <Grid
          container
          justify="center"
          alignItems="center"
          className={classes.loadingDiv}>
          <SVGLoading width="59px" height="59px" />
        </Grid>
      ) : (
        <Switch>
          <Route exact path="/login" component={LoginPage} />
          <ProtectedRoute exact path="/" component={Dashboard} />
          <ProtectedRoute exact path="/clientAdmin" component={ClientAdmin} />
          <ProtectedRoute exact path="/projects" component={ProjectsPage} />
          <ProtectedRoute exact path="/opscenter" component={OpsCenter} />
          <ProtectedRoute
            exact
            path="/projects/new"
            component={NewProjectPage}
          />
          <ProtectedRoute exact path="/project/:id" component={ProjectPage} />
          <Route path="/unauthorized" component={Unauthorized} />
          <Route path="*" component={ErrorPage} />
        </Switch>
      )}
      <Toast />
      {//Banner to show offline status
      !online && (
        <Grid
          container
          className="online-banner"
          justify="center"
          alignItems="center">
          <p>You are not connected to internet.</p>
        </Grid>
      )}
    </ThemeProvider>
  );
}

const ProtectedRoute = withRouter(({ path, component, ...props }) => {
  const accessor = useSelector(state => state.accessor);
  var user = firebase.auth().currentUser;
  let propObject = parse(props.location.search.slice(1));

  // Authenticated user -> go to route
  if (user && userHasAccess(path, accessor.groups))
    return <Route exact path={path} component={component} {...props} />;
  else if (user) return <Redirect to="/" />;
  // Unauthenticated user -> go to login path, but redirect to requested page once logged in
  else
    return (
      <Redirect
        to={`/login?redirect=${path.slice(1, 100)}${Object.entries(propObject)
          .map(([key, value]) => `&${key}=${value}`)
          .join("")}`}
      />
    );
});

// Check if user groups has access to roles defined
const userHasAccess = (path, groups) =>
  path === "/" ||
  pages
    .find(page => page.path === path.split("/")[1])
    .groups.some(group => groups.some(memberGroup => memberGroup === group));

export default withRouter(App);
