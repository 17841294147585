import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import { openErrorToast } from "../../redux/actions";
import SVGLoading from "../SVGLoading";
import { groupBy } from "lodash";

import RefreshIcon from "@material-ui/icons/Refresh";
import LabelIcon from "@material-ui/icons/Label";

import {
  Grid,
  Typography,
  Tabs,
  Tab,
  Button,
  Chip,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField
} from "@material-ui/core";
import GetAppIcon from "@material-ui/icons/GetApp";

import { makeStyles } from "@material-ui/styles";
import { getAssetsFromOrderId } from "../../Api";
import { arrayToCSV, flattenObject } from "../../constants";
import { CSVDownload, CSVLink } from "react-csv";

const useStyles = makeStyles(theme => ({
  actions: { margin: "23px" },
  actionButton: { margin: "14px" },
  subsection: {
    marginTop: "23px"
  },
  packingRow: {
    marginTop: "15px"
  },
  chipAsset: {
    margin: "2.5px"
  },
  assetCircle: {
    height: "24px"
  },
  correctUnique: {
    backgroundColor: "#41b800"
  },
  correctDuplicate: {
    backgroundColor: "#D0EFC0"
  },
  packedElsewhere: {
    backgroundColor: "#FFCED4"
  },
  extraAsset: {
    backgroundColor: "#FF5252"
  },
  notPacked: {
    backgroundColor: "#a3a3a3"
  },
  legend: {
    margin: "15px",
    border: "1px solid #e5e5e5",
    borderRadius: "4px",
    padding: "14px"
  },
  noAssets: {},
  assetContainer: {
    marginTop: "23px"
  }
}));

function PackingData(props) {
  const accessor = useSelector(state => state.accessor);
  const [triggerDownload, setTriggerDownload] = useState(false);
  const dispatch = useDispatch();
  const isOpsUser = accessor.groups.some(group =>
    [
      "Developer Administrators",
      "Application Administrators",
      "Operations User of Pikkol Organization"
    ].includes(group)
  );

  const classes = useStyles();
  const { orderId, projectRefId, customerName, fromAddress, toAddress } = props;
  const [busy, setBusy] = useState(false);

  const [packing, setPacking] = useState({
    search: "",
    showValue: "packed",
    assets: []
  });
  const [dialog, setDialog] = useState({
    open: false,
    asset: {}
  });

  const unordered = groupBy(
    packing.assets.filter(row => ~row.scanCode.indexOf(packing.search)),
    packing.showValue
  );
  const displayData = {};
  Object.keys(unordered)
    .sort()
    .forEach(key => {
      displayData[key] = unordered[key];
    });

  const getPackingStatus = async () => {
    setBusy(true);
    const { assets } = orderId ? await getAssetsFromOrderId(orderId) : [];

    const displayAssets = assets.map((row, index) => ({
      ...row,
      isDuplicate: assets
        .filter((a, innerIndex) => innerIndex <= index)
        .some(
          (innerRow, innerIndex) =>
            innerRow.scanCode === row.scanCode && innerIndex !== index
        ),
      packageLabel: row.packageOrderId
        ? `${
            row.packageOrderId
          }-${row.packageSequenceNumber.toString().padStart(3, "0")}`
        : null,
      packed: !!row.packageSequenceNumber,
      correct: row.assetOrderId === row.packageOrderId
    }));
    setPacking({
      ...packing,
      assets: displayAssets
    });
  };

  useEffect(() => {
    setBusy(false);
  }, [packing.assets]);

  useEffect(() => {
    getPackingStatus();
    const refreshPackingData = setInterval(() => {
      getPackingStatus();
    }, 600000);
    return () => {
      clearInterval(refreshPackingData);
    };
  }, [props.orderId]);

  return busy ? (
    <Grid container justify="center" alignItems="center">
      <SVGLoading width="37px" height="37px" />
    </Grid>
  ) : (
    <Grid container className={classes.assetContainer}>
      {packing.assets.length === 0 ? (
        <Typography variant="body1" className={classes.noAssets}>
          No assets tagged so far
        </Typography>
      ) : (
        <Grid>
          <Grid container direction="row" className={classes.subsection}>
            <Typography variant="h3">Packing data</Typography>
            <Button
              onClick={() => {
                getPackingStatus();
              }}
              size="small"
              style={{ margin: "0px 30px" }}
              startIcon={<RefreshIcon />}>
              Refresh
            </Button>
          </Grid>

          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="center">
            <Grid item>
              <Tabs
                value={packing.showValue}
                indicatorColor="primary"
                textColor="primary"
                onChange={(e, showValue) =>
                  setPacking({ ...packing, showValue })
                }
                variant="standard">
                <Tab label="Is Packed" value="packed" />
                <Tab label="By Module" value="groupCode" />
                <Tab label="By Package" value="packageLabel" />
              </Tabs>
            </Grid>
            <Grid item>
              <TextField
                variant="outlined"
                value={packing.search}
                onChange={e =>
                  setPacking({ ...packing, search: e.target.value })
                }
                label="Search here"
              />
            </Grid>
            <Grid item>
              <b>
                Planned Assets :&nbsp;
                {
                  [
                    ...new Set(
                      packing.assets
                        .filter(a => +a.assetOrderId === +props.orderId)
                        .map(a => a.scanCode)
                    )
                  ].length
                }
              </b>
            </Grid>
          </Grid>

          <Grid container>
            {Object.entries(displayData).map(([key, assets]) => (
              <Grid
                container
                key={key}
                className={classes.packingRow}
                direction="row"
                justify="flex-start"
                alignItems="flex-start">
                <Grid className="width15pc keyContainer">
                  <b>
                    {packing.showValue === "packed"
                      ? key === "true"
                        ? "Packed"
                        : "Not packed"
                      : key === "null"
                      ? packing.showValue === "groupCode"
                        ? "Not grouped"
                        : "Not packed"
                      : packing.showValue === "packageSequenceNumber"
                      ? `Package-${key}`
                      : key}
                  </b>
                  <SpanIfGreaterThan0
                    value={
                      assets.filter(
                        a => a.packed && a.correct && !a.isDuplicate
                      ).length
                    }
                    color="#41B800"
                  />
                  <SpanIfGreaterThan0
                    value={
                      assets.filter(a => a.packed && a.correct && a.isDuplicate)
                        .length
                    }
                    color="#D0EFC0"
                  />

                  <SpanIfGreaterThan0
                    color="#FF5252"
                    value={
                      assets.filter(
                        a =>
                          a.packed && !a.correct && +a.assetOrderId !== +orderId
                      ).length
                    }
                  />
                  <SpanIfGreaterThan0
                    color="#FFCED4"
                    value={
                      assets.filter(
                        a =>
                          a.packed && !a.correct && +a.assetOrderId === +orderId
                      ).length
                    }
                  />
                  <SpanIfGreaterThan0
                    color="#a3a3a3"
                    value={
                      assets.filter(a => !a.packed).length > 0 &&
                      assets.filter(a => !a.packed).length
                    }
                  />
                </Grid>
                <Grid className="width75pc" container style={{ margin: "8px" }}>
                  {assets.map((asset, i) => (
                    <Chip
                      key={i}
                      className={`${classes.chipAsset} ${
                        asset.packed
                          ? asset.correct
                            ? !asset.isDuplicate
                              ? classes.correctUnique
                              : classes.correctDuplicate
                            : +asset.assetOrderId === +orderId
                            ? classes.packedElsewhere
                            : classes.extraAsset
                          : classes.notPacked
                      } ${
                        packing.showValue === "packed" && classes.assetCircle
                      }`}
                      onClick={() => setDialog({ open: true, asset })}
                      label={
                        <span>
                          {packing.showValue === "packed" || asset.scanCode}
                        </span>
                      }
                    />
                  ))}
                </Grid>
              </Grid>
            ))}
            <Grid
              container
              direction="row"
              justify="space-around"
              alignItems="center"
              className={classes.legend}>
              <Typography variant="body2">
                <b>Legend</b>
              </Typography>
              <LegendChip
                chipClasses={classes.correctUnique}
                label="Correct and Unique"
              />
              <LegendChip
                chipClasses={classes.correctDuplicate}
                label="Correct and Duplicate"
              />
              <LegendChip
                chipClasses={classes.packedElsewhere}
                label="Packed elsewhere"
              />
              <LegendChip
                chipClasses={classes.extraAsset}
                label="Extra assets"
              />
              <LegendChip chipClasses={classes.notPacked} label="Not Packed" />
            </Grid>
            <Grid
              container
              direction="row"
              className={classes.actions}
              justify="center">
              {isOpsUser && (
                <Grid item>
                  <Button
                    color="primary"
                    variant="outlined"
                    startIcon={<LabelIcon />}
                    className={classes.actionButton}
                    onClick={() => {
                      !fromAddress
                        ? dispatch(
                            openErrorToast(
                              "From address is not there. Is the phase created? "
                            )
                          )
                        : window.open(
                            `https://docgen.pikkol.com/labels?orderId=${orderId}&name=${encodeURI(
                              customerName
                            )}-${encodeURI(
                              projectRefId
                            )}&fromAddress=${encodeURI(
                              fromAddress
                            )}&toAddress=${encodeURI(
                              toAddress
                            )}&packages=${Math.ceil(
                              packing.assets.length / 4
                            )}`,
                            "_blank"
                          );
                    }}>
                    Labels
                  </Button>
                </Grid>
              )}
              <Grid item>
                <Button
                  startIcon={<GetAppIcon />}
                  color="primary"
                  variant="outlined"
                  className={classes.actionButton}
                  onClick={() => {
                    setTriggerDownload(true);
                  }}>
                  <CSVLink
                    data={packing.assets.map(asset => flattenObject(asset))}
                    target="_blank"
                    filename={`Packing List - ${projectRefId}.csv`}
                    style={{ color: "inherit", textDecoration: "none" }}>
                    Packing Data
                  </CSVLink>
                </Button>
              </Grid>
            </Grid>
          </Grid>

          <Dialog
            open={dialog.open}
            onClose={() => setDialog({ open: false, asset: {} })}>
            <DialogTitle>{dialog.asset.scanCode || ""}</DialogTitle>

            <DialogContent>
              {dialog.asset.isDuplicate ? (
                <span className={classes.correctDuplicate}>
                  Duplicate: Yes
                  <br />
                </span>
              ) : (
                ``
              )}
              Packed in: {dialog.asset.packageOrderId}-
              {dialog.asset.packageSequenceNumber} <br />
              Asset OrderId: {dialog.asset.assetOrderId} <br />
              <br />
              <b>Asset Data:</b> <br />
              <br />
              {dialog.asset.customJson &&
                Object.entries(dialog.asset.customJson).map(([key, value]) => (
                  <div key={key} className="asset-data">
                    {key} : {value} <br />
                  </div>
                ))}
            </DialogContent>
          </Dialog>
        </Grid>
      )}
    </Grid>
  );

  function SpanIfGreaterThan0(props) {
    const { value, color } = props;
    return (
      <span style={{ color }}>{value > 0 ? <>&emsp;&emsp;{value}</> : ``}</span>
    );
  }
  function LegendChip(props) {
    const { chipClasses, label } = props;
    return (
      <Grid item>
        <Grid container direction="row" alignItems="center">
          <Chip
            className={`${classes.chipAsset} ${classes.assetCircle} ${chipClasses}`}
          />
          <Typography variant="body2">{label} &emsp;&emsp;</Typography>
        </Grid>
      </Grid>
    );
  }
}

PackingData.propTypes = {
  orderId: PropTypes.number.isRequired
};

export default PackingData;
