import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Grid, Tabs, Tab } from "@material-ui/core";
import { Header } from "../../components";

import ClientSelector from "../../components/ClientSelector";
import ClientAccountHandler from "./ClientAccountHandler";
import ClientLocationHandler from "./ClientLocationHandler";
import ClientPOCHandler from "./ClientPOCHandler";
import ClientCompanyList from "./ClientCompanyList";
import ClientEntityHandler from "./ClientEntityHandler";
import ClientTimeSlotHandler from "./ClientTimeSlotHandler";

export default function ClientAdmin(props) {
  const [selectedTab, setSelectedTab] = useState(0);
  const clientId = useSelector(state => state.clientId);

  return (
    <Grid className="mainContainer">
      <Header title="Administrator" />
      <Grid container direction="column">
        <ClientSelector style={{ margin: "10px", width: "200px" }} />
        {clientId && (
          <Grid>
            <Tabs
              value={selectedTab}
              onChange={(e, newValue) => setSelectedTab(newValue)}>
              <Tab value={0} label="Accounts" />
              <Tab value={1} label="Location" />
              <Tab value={2} label="POC" />
              <Tab value={3} label="Time slots" />
              <Tab value={4} label="Companies" />
              <Tab value={5} label="Entities" />
            </Tabs>
            {selectedTab === 0 && <ClientAccountHandler />}
            {selectedTab === 1 && <ClientLocationHandler />}
            {selectedTab === 2 && <ClientPOCHandler />}
            {selectedTab === 3 && <ClientTimeSlotHandler />}
            {selectedTab === 4 && <ClientCompanyList />}
            {selectedTab === 5 && <ClientEntityHandler />}
          </Grid>
        )}
      </Grid>
    </Grid>
  );
}
