import React from "react";
import { TextField, Grid } from "@material-ui/core";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng
} from "react-places-autocomplete";

const google = window.google;

export default function AutoCompletePlaceSelector(props) {
  return (
    <PlacesAutocomplete
      value={props.address}
      searchOptions={{
        location: new google.maps.LatLng(13, 77),
        radius: 3000
      }}
      shouldFetchSuggestions={props.address.length > 2}
      onChange={newAddress => props.onChange(newAddress)}
      onSelect={selected =>
        geocodeByAddress(selected)
          .then(async res => {
            const result = res[0];
            const { lat, lng } = await getLatLng(result);

            return { lat, lng, placeId: result.place_id };
          })
          .then(({ lat, lng, placeId }) =>
            props.setAddressValue({
              latitude: lat,
              longitude: lng,
              name: selected,
              placeId
            })
          )
      }>
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
        <div>
          <TextField
            required
            error={props.address === ""}
            style={{ ...props.textFieldStyle }}
            autoFocus
            label="Search places on Google...."
            {...getInputProps({
              className: "location-search-input"
            })}
          />
          <Grid
            container
            direction="column"
            style={{
              ...props.suggestionContainerStyle,
              whiteSpace: "nowrap",
              maxWidth: "40%",
              marginLeft: "10px",
              textOverflow: "ellipsis",
              overflow: "hidden",
              borderRadius: "4px",
              position: "fixed",
              fontSize: "0.9em",
              backgroundColor: "#ebe9ee",
              zIndex: "10000"
            }}>
            {loading && <div>Loading...</div>}
            {suggestions.map(suggestion => {
              const className = suggestion.active
                ? "suggestion-item--active"
                : "suggestion-item";
              // inline style for demonstration purpose
              const style = {
                cursor: "pointer",
                padding: "5px",
                ...(suggestion.active
                  ? {
                      backgroundColor: "#439fd9",
                      color: "white"
                    }
                  : {})
              };
              return (
                <span
                  {...getSuggestionItemProps(suggestion, {
                    className,
                    style
                  })}>
                  <i>{suggestion.description}</i>
                </span>
              );
            })}
          </Grid>
        </div>
      )}
    </PlacesAutocomplete>
  );
}
