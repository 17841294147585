import { getToken, baseUrl } from "./index";

/**
 * For packing widget, get packing summaries for various order ids
 * @param {number[]} orderIds
 */
export const getPackingSummariesFromOrderIds = async orderIds =>
  fetch(`${baseUrl}/tallyo/packing-summary?orderIds=${orderIds.join(",")}`, {
    headers: {
      Authorization: `bearer ${await getToken()}`
    }
  }).then(response => response.json());
/**
 * For tally widget and data component, get tallies by order Id
 * @param {number[]} orderIds
 */
export const getTalliesFromOrderIds = async orderIds =>
  await fetch(`${baseUrl}/tallyo/tallies?orderIds=${orderIds.join(",")}`, {
    headers: {
      Authorization: `bearer ${await getToken()}`
    }
  }).then(response => response.json());

/**
 * Get Tally Data
 * @param {number} tallyId
 */

export const getTallyData = async tallyId =>
  fetch(`${baseUrl}/tallyo/tally/${tallyId}`, {
    headers: {
      Authorization: `bearer ${await getToken()}`
    }
  }).then(response => response.json());

/**
 * For packing data component, get all assets for given order Id
 * @param {number} orderId
 */
export const getAssetsFromOrderId = async orderId =>
  await fetch(`${baseUrl}/tallyo/assets?orderId=${orderId}`, {
    headers: {
      Authorization: `bearer ${await getToken()}`
    }
  }).then(response => response.json());

/**
 * Add assets to tallyo by order Id
 * @param {object[]} assets
 * @param {number} orderId
 */
export const addAssetsToTallyo = async (assets, orderId) =>
  await fetch(`${baseUrl}/tallyo/assets`, {
    method: "POST",
    headers: {
      Authorization: `bearer ${await getToken()}`,
      "Content-Type": "application/json"
    },
    body: JSON.stringify({ orderId, assets })
  }).then(response => response.json());
