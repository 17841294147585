import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { openErrorToast } from "../../redux/actions";

import { Grid } from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import { getCompaniesForClient } from "../../Api";
import { companyColumns, tableOptions } from "../../constants";

export default function ClientCompanyList(props) {
  const dispatch = useDispatch();
  const accessor = useSelector(state => state.accessor);
  const clientId = useSelector(state => state.clientId);

  const [clientCompanies, setClientCompanies] = useState([]);

  const retrieveCompanies = async () => {
    const searchClientId = accessor.clientId || clientId;
    if (!clientId) dispatch(openErrorToast("Choose a client"));
    else {
      const { companies } = await getCompaniesForClient(searchClientId);
      setClientCompanies(companies);
    }
  };

  useEffect(() => {
    retrieveCompanies();
  }, [clientId]);

  return (
    <Grid>
      <MUIDataTable
        data={clientCompanies}
        options={tableOptions}
        columns={companyColumns}
      />
    </Grid>
  );
}
