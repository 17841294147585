import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { selectAll } from "d3";

function TallyWidget(props) {
  const { tallies, width, height, id, className } = props;
  useEffect(() => {
    var svg = selectAll(`.${className}`);
    svg.html("");

    tallies.forEach((tally, i) => {
      if (i !== 0) {
        svg
          .append("path")
          .attr("d", "M 0 0 L 0 20 L 10 10 z")
          .attr("fill", "#dadada")
          .style("transform", `translate(${i * 100 - 24}px,8px)`);
      }

      svg
        .append("text")
        .transition()
        .duration(200)
        .attr("x", i * 100 + 16)
        .attr("y", 24)
        .attr("font-size", "14px")
        .text(`${tally.committedPackages} / ${tally.totalPackages}`)
        .attr(
          "fill",
          tally.committedPackages === tally.totalPackages
            ? `#444444`
            : `#FF5252`
        );
    });
  }, [props]);

  return (
    <svg
      id={id || "tally-widget"}
      className={className || "tally-widget"}
      width={width || "400"}
      height={height || "40"}></svg>
  );
}

TallyWidget.propTypes = {
  tallies: PropTypes.array,
  id: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  className: PropTypes.string
};

export default TallyWidget;
