import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { openErrorToast } from "../../redux/actions";

import { getPOCsForClient, addPOCToClient } from "../../Api";
import {
  Grid,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions
} from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import useDebounceState from "../../customHooks/useDebounceState";
import SearchIcon from "@material-ui/icons/Search";
import { pocColumns, phoneRegex } from "../../constants";

export default function ClientLocationHandler(props) {
  const dispatch = useDispatch();
  const accessor = useSelector(state => state.accessor);
  const clientId = useSelector(state => state.clientId);
  const [dialog, setDialog] = useState({ open: false });
  const [newPoc, setNewPoc] = useState({
    name: "",
    email: "",
    phoneNumber: "",
    nameError: false,
    emailError: false,
    phoneNumberError: false
  });
  const [search, setSearch] = useState("");
  const debouncedSearch = useDebounceState({ value: search, delay: 1000 });
  const [clientPOCs, setPOCs] = useState({ pocs: [], count: 0 });
  const [serverCall, setServerCall] = useState(false);
  const [pagination, setPagination] = useState({ page: 0, rowsPerPage: 10 });
  const thisTableOptions = {
    search: false,
    filter: false,
    selectableRows: "none",
    print: false,
    viewColumns: false,
    page: pagination.page,
    rowsPerPage: pagination.rowsPerPage,
    count: clientPOCs.count,
    onChangeRowsPerPage: rowsPerPage =>
      setPagination({ ...pagination, rowsPerPage, page: 0 }),
    rowsPerPageOptions: [1, 10, 100, 500],
    download: false,
    serverSide: true,
    onTableChange: (action, tableState) => {
      const { page } = tableState;
      if (action === "changePage") setPagination({ ...pagination, page });
    },
    customToolbar: () => (
      <Grid container direction="row" justify="flex-end" spacing={3}>
        <TextField
          id="search"
          value={search.value}
          onChange={e => setSearch(e.target.value)}
          InputProps={{
            endAdornment: <SearchIcon />
          }}
        />
        <Button
          color="primary"
          variant="contained"
          style={{ marginLeft: "30px" }}
          onClick={() => setDialog({ open: true })}>
          New POC
        </Button>
      </Grid>
    )
  };

  const getClientPOCs = async () => {
    const searchClientId = accessor.clientId || clientId;
    if (!searchClientId) dispatch(openErrorToast("Choose a client"));
    else {
      const pocs = await getPOCsForClient(
        searchClientId,
        pagination.rowsPerPage,
        pagination.page,
        search
      );
      setPOCs(pocs);
    }
  };

  const handleAddPOC = async () => {
    setServerCall(true);
    if (newPoc.name === "" || !phoneRegex.test(newPoc.phoneNumber)) {
      setServerCall(false);
      setNewPoc({
        ...newPoc,
        nameError: newPoc.name === "",
        phoneNumberError: !phoneRegex.test(newPoc.phoneNumber)
      });
    } else {
      const searchClientId = accessor.clientId || clientId;
      if (!searchClientId) dispatch(openErrorToast("Choose a client"));
      else {
        const { name, email, phoneNumber } = newPoc;
        await addPOCToClient(searchClientId, { name, email, phoneNumber });
        setServerCall(false);
        setNewPoc({
          name: "",
          nameError: false,
          email: "",
          emailError: false,
          phoneNumber: "",
          phoneNumberError: false
        });
        setDialog({ open: false });
        getClientPOCs();
      }
    }
  };
  useEffect(() => {
    getClientPOCs();
  }, [clientId, pagination, debouncedSearch.length > 2 && debouncedSearch]);

  return (
    <Grid>
      <MUIDataTable
        data={clientPOCs.pocs}
        columns={pocColumns}
        options={thisTableOptions}
      />
      <Dialog open={dialog.open} onClose={() => setDialog({ open: false })}>
        <DialogTitle>New POC</DialogTitle>
        <DialogContent>
          <Grid direction="column" container>
            <TextField
              id="poc-name"
              value={newPoc.name}
              onChange={e =>
                setNewPoc({ ...newPoc, name: e.target.value, nameError: false })
              }
              error={newPoc.nameError}
              label="Enter POC Name"
            />
            <TextField
              label="Enter POC Email"
              value={newPoc.email}
              onChange={e =>
                setNewPoc({
                  ...newPoc,
                  email: e.target.value,
                  emailError: false
                })
              }
              id="poc-email"
            />
            <TextField
              label="Enter POC Phone"
              value={newPoc.phoneNumber}
              error={newPoc.phoneNumberError}
              onChange={e =>
                setNewPoc({
                  ...newPoc,
                  phoneNumber: e.target.value,
                  phoneNumberError: false
                })
              }
              id="poc-phone"
            />
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDialog({ open: false })}>Cancel</Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleAddPOC}
            disabled={serverCall}>
            Add
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
}
