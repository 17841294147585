import React, { useState, useEffect } from "react";
import { auth } from "firebase/app";
import { useDispatch } from "react-redux";
import { openSuccessToast, openErrorToast } from "../../redux/actions";

import logo from "./google_logo.svg";

import {
  Button,
  Grid,
  TextField,
  Tabs,
  Tab,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent
} from "@material-ui/core";

import "./index.css";
import { parse } from "querystring";

const heading = {
  signIn: "Sign in with Email and Password",
  register: "Register with your official account",
  forgot: "Enter email id to send reset password link"
};

const buttonLabels = {
  signIn: "Sign In",
  register: "Register",
  forgot: "SEND RESET LINK"
};

export default function LoginPage(props) {
  const urlParams = parse(props.location.search.slice(1, 10000));
  const [emailDialog, setEmailDialog] = useState({ open: false, email: "" });
  const dispatch = useDispatch();
  const [context, setContext] = useState(urlParams.context || "signIn");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const loginWIthGoogle = () => {
    var provider = new auth.GoogleAuthProvider();
    provider.setCustomParameters({
      prompt: "select_account"
    });

    auth()
      .signInWithPopup(provider)
      .then(async result => {})
      .catch(error => {
        console.log(error);
      });
  };
  const forgot = () => {
    auth()
      .sendPasswordResetEmail(email, {
        url: `${process.env.REACT_APP_BASE_URL}/login`
      })
      .then(result => {
        dispatch(openSuccessToast("Password reset email sent."));
        setContext("signIn");
      })
      .catch(error => console.log(error));
  };

  useEffect(() => {
    if (auth().isSignInWithEmailLink(window.location.href)) {
      // Signed in from email link
      setEmailDialog({ ...emailDialog, open: true });
    }
  }, []);

  const handleConfirmEmail = () => {
    auth()
      .signInWithEmailLink(emailDialog.email, window.location.href)
      .then(async result => {})
      .catch(error => {
        if (error.code === "auth/invalid-action-code") {
          dispatch(
            openErrorToast(
              "Code is either expired, or already used by another user."
            )
          );
        }
      });
  };

  const login = () => {
    auth()
      .signInWithEmailAndPassword(email, password)
      .then(function(result) {})
      .catch(function(error) {
        setEmail("");
        setPassword("");
        if (error.code === "auth/user-not-found")
          dispatch(openErrorToast("User doesn't exist. Please register."));
        else if (error.code === "auth/wrong-password")
          dispatch(
            openErrorToast(
              "Wrong password. Please enter correct password or forgot password."
            )
          );

        console.log(error);
      });
  };

  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      style={{ backgroundColor: "white", marginTop: "100px" }}
      justify="center">
      <Tabs
        indicatorColor="primary"
        textColor="primary"
        value={context}
        style={{ fontWeight: "400" }}
        onChange={(e, newValue) => setContext(newValue)}>
        <Tab label="Sign In" value="signIn" />
        <Tab label="Reset Password" value="forgot" />
      </Tabs>
      <p style={{ marginTop: "40px" }}>{heading[context]}</p>
      <TextField
        variant="outlined"
        style={{ marginTop: "10px" }}
        type="email"
        className="fullWidthMax300"
        autoComplete="email"
        placeholder="Enter email address"
        value={email}
        onChange={e => setEmail(e.target.value)}
      />
      {context !== "forgot" && (
        <TextField
          variant="outlined"
          className="fullWidthMax300"
          style={{ marginTop: "10px" }}
          type="password"
          placeholder="Enter Password"
          value={password}
          onChange={e => setPassword(e.target.value)}
        />
      )}
      {context === "register" && (
        <TextField
          variant="outlined"
          type="password"
          className="fullWidthMax300"
          placeholder="Confirm Password"
          value={confirmPassword}
          error={!(confirmPassword === password)}
          onChange={e => setConfirmPassword(e.target.value)}
        />
      )}

      <Button
        variant="contained"
        className="loginButton fullWidthMax300"
        color="primary"
        style={{
          paddingRight: "12px",
          margin: "10px 0px 20px 0px"
        }}
        onClick={() => (context === "signIn" ? login() : forgot())}>
        {buttonLabels[context]}
      </Button>

      <p>OR</p>
      <Button
        className="loginButton fullWidthMax300"
        color="primary"
        variant="outlined"
        style={{
          margin: "30px 0px",
          height: "50px",
          paddingRight: "12px"
        }}
        onClick={() => loginWIthGoogle()}>
        <img src={logo} style={{ margin: "10px" }} alt="Sign in with Google " />
        Sign In with Google
      </Button>
      <Dialog
        open={emailDialog.open}
        onClose={() => setEmailDialog({ open: false, email: "" })}
        style={{ margin: "20px" }}>
        <DialogTitle>Verify Email address</DialogTitle>
        <DialogContent>
          <TextField
            label="Verify email"
            type="email"
            value={emailDialog.email}
            onChange={e =>
              setEmailDialog({ ...emailDialog, email: e.target.value })
            }
          />
        </DialogContent>

        <DialogActions>
          <Button
            color="primary"
            variant="contained"
            onClick={handleConfirmEmail}>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
}
