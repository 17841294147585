import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { auth } from "firebase/app";

import {
  AppBar,
  Toolbar,
  Drawer,
  ListSubheader,
  IconButton,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText
} from "@material-ui/core";

import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import MenuIcon from "@material-ui/icons/Menu";
import HomeIcon from "@material-ui/icons/Home";

import pages from "../../components/pagesList";

export default function Header(props) {
  const accessor = useSelector(state => state.accessor);

  const [drawer, setDrawer] = useState(false);

  const sidebarItems = pages
    .filter(page => page.sidebar === true) // Filter only pages which should be shown in the sidebar
    .map(
      (page, index) =>
        accessor.groups &&
        page.groups.some(
          itemGroup => accessor.groups.some(group => itemGroup === group) // If user has a role which has access to this sidebar element
        ) && (
          <ListItem
            button
            component={Link}
            id={page.path}
            key={index}
            to={`/${page.path}`}
            disabled={props.page === index}>
            <ListItemIcon>{page.icon}</ListItemIcon>
            <ListItemText>{page.name}</ListItemText>
          </ListItem>
        )
    );

  return (
    <div>
      <AppBar position="fixed" style={{ backgroundColor: "#000000" }}>
        <Toolbar>
          <IconButton onClick={() => setDrawer(true)} aria-label="Menu">
            <MenuIcon style={{ color: "#FFFFFF" }} />
          </IconButton>
          <h3 style={{ flex: 1, color: "#FFFFFF" }}>{props.title}</h3>
        </Toolbar>
      </AppBar>
      <Drawer open={drawer} onClose={() => setDrawer(false)}>
        <List component="nav">
          <ListSubheader component="div">
            {accessor && accessor.name}
          </ListSubheader>
          <ListItem button component={Link} to="/">
            <ListItemIcon>
              <HomeIcon />
            </ListItemIcon>
            <ListItemText>Dashboard</ListItemText>
          </ListItem>
          <Divider />
          {sidebarItems}
          <Divider />

          <ListItem button onClick={() => auth().signOut()}>
            <ListItemIcon>
              <ExitToAppIcon />
            </ListItemIcon>
            <ListItemText primary="Logout" />
          </ListItem>
        </List>
      </Drawer>
    </div>
  );
}
