import React, { useEffect } from "react";
import {
  selectAll,
  scaleLinear,
  arc,
  interpolate,
  interpolateNumber,
  event
} from "d3";
import "./index.css";

export default function RadialChart(props) {
  const {
    metrics = {},
    id,
    width = 400,
    height = 120,
    className,
    padding = 15,
    arcPadding = 5
  } = props;

  let tooltip = selectAll(".tooltip");

  useEffect(() => {
    let svg = selectAll(`.${className}`);
    svg.html("");

    let g = svg
      .append("g")
      .attr("transform", "translate(" + width / 2 + "," + height / 2 + ")");

    const innerWidth = width - 2 * padding,
      innerHeight = height - 2 * padding,
      chartRadius = Math.min(innerHeight, innerWidth) / 2,
      PI = Math.PI,
      arcMinRadius = 5;

    const scale = scaleLinear()
      .domain([0, 1])
      .range([0, 1.5 * PI]);

    const numArcs = metrics.length;
    const arcWidth =
      (chartRadius - arcMinRadius - numArcs * arcPadding) / numArcs;

    const getInnerRadius = index =>
        arcMinRadius + (numArcs - (index + 1)) * (arcWidth + arcPadding),
      getOuterRadius = index => getInnerRadius(index) + arcWidth;

    const arcFn = arc()
      .innerRadius((d, i) => getInnerRadius(i))
      .outerRadius((d, i) => getOuterRadius(i))
      .startAngle(0)
      .endAngle((d, i) => scale(d))
      .cornerRadius(2);

    const labels = g
      .append("g")
      .attr("class", "labels")
      .selectAll(".labels")
      .data(metrics)
      .enter()
      .append("text")
      .attr("text-anchor", "end")
      .style("font-size", "14px")
      .attr("x", -5)
      .attr("y", (d, i) => -getInnerRadius(i) - 7)
      .text(d => d.name);

    const backgroundArcs = g
      .append("g")
      .attr("class", "backgrounds")
      .selectAll("path")
      .data(metrics)
      .enter()
      .append("path")
      .attr("class", "arc")
      .attr("fill", "#f1f1f1")
      .attr("d", (d, i) => arcFn(1, i))
      .on("mouseover", (d, i) => {
        tooltip
          .style("left", event.pageX + 10 + "px")
          .style("top", event.pageY - 25 + "px")
          .style("display", "inline-block")
          .html(`${d.description} <br/> ${+(d.value * 100).toFixed(0)} %`);
      })
      .on("mouseout", () => {
        tooltip.style("display", "none");
      });

    const arcs = g
      .append("g")
      .attr("class", "data")
      .selectAll("path")
      .data(metrics)
      .enter()
      .append("path")
      .attr("class", "arc")
      .attr("fill", (d, i) => d.color)
      // Since hover doesn't carry over if another shape is added in front
      .on("mouseover", (d, i) => {
        tooltip
          .style("left", event.pageX + 10 + "px")
          .style("top", event.pageY - 25 + "px")
          .style("display", "inline-block")
          .html(`${d.description} <br/> ${+(d.value * 100).toFixed(0)} %`);
      })
      .on("mouseout", () => {
        tooltip.style("display", "none");
      })

      .transition()
      .delay((d, i) => i * 200)
      .duration(1000)
      .attrTween("d", (d, i) => {
        const interpolateFn = interpolate(0, d.value);
        return t => arcFn(interpolateFn(t), i);
      });
  }, [props]);
  return (
    <svg
      id={id || "radial-widget"}
      className={className || "radial-widget"}
      width={width}
      height={height}></svg>
  );
}
