import React from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Typography,
  Grid,
  Button
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { createOrderInAdmin } from "../../Api/admin";

const useStyles = makeStyles(theme => ({
  label: {
    width: "200px"
  }
}));

export default function CreateOrderDialog(props) {
  const classes = useStyles();
  const { open, order } = props;

  const GridItem = props => (
    <Grid item>
      <Grid container direction="row">
        <Typography variant="body1" className={classes.label}>
          {props.label}
        </Typography>
        <Typography variant="body1">
          <strong>{props.value}</strong>
        </Typography>
      </Grid>
    </Grid>
  );

  return (
    <Dialog open={open} onClose={() => props.onClose()}>
      <DialogTitle>Create Order</DialogTitle>

      <DialogContent>
        <Grid container direction="column">
          <GridItem label="Order Date" value={order.order_date} />
          <GridItem
            label="From Address"
            value={order.from_address && order.from_address.name}
          />
          <GridItem
            label="To Address"
            value={order.to_address && order.to_address.name}
          />
          <GridItem
            label="Invoicing Information"
            value={order.invoicing_information}
          />
          <GridItem
            label="Source lift availability"
            value={order.source_lift_availability ? "Yes" : "No"}
          />
          <GridItem
            label="Destination lift availability"
            value={order.destination_lift_availability ? "Yes" : "No"}
          />
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          variant="contained"
          disabled={
            !order.order_date || !order.from_address || !order.to_address
          }
          onClick={async () => {
            const { orderId } = await createOrderInAdmin(order);
            props.updateOrderId(orderId);
            props.onClose();
          }}>
          Create Order
        </Button>
      </DialogActions>
    </Dialog>
  );
}
