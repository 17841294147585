import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getLocationsForClient, addLocationToClient } from "../../Api";

import SearchIcon from "@material-ui/icons/Search";
import {
  Grid,
  Button,
  DialogTitle,
  DialogActions,
  TextField,
  Dialog,
  DialogContent
} from "@material-ui/core";

import useDebounceState from "../../customHooks/useDebounceState";
import { Skeleton } from "@material-ui/lab";
import MUIDataTable from "mui-datatables";
import { locationColumns } from "../../constants";

import AutoCompletePlaceSelector from "../../components/AutoCompletePlaceSelector";
import { openErrorToast } from "../../redux/actions";

export default function ClientLocationHandler(props) {
  const dispatch = useDispatch();
  const accessor = useSelector(state => state.accessor);
  const clientId = useSelector(state => state.clientId);
  const [dialog, setDialog] = useState(false);
  const [serverCall, setServerCall] = useState(false);
  const [pagination, setPagination] = useState({ page: 0, rowsPerPage: 10 });
  const [search, setSearch] = useState("");
  const debouncedSearch = useDebounceState({ value: search, delay: 1000 });
  const [location, setLocation] = useState({
    nickname: "",
    name: "",
    latitude: null,
    longitude: null,
    placeId: null
  });
  const [clientLocations, setLocations] = useState({ locations: [], count: 0 });
  const getClientLocations = async () => {
    setServerCall(true);
    const searchClientId = accessor.clientId || clientId;
    if (!searchClientId) dispatch(openErrorToast("Choose a client"));
    else {
      const { locations, count } = await getLocationsForClient(
        searchClientId,
        pagination.rowsPerPage,
        pagination.page,
        search
      );
      setServerCall(false);
      setLocations({ locations, count });
    }
  };
  const thisTableOptions = {
    search: false,
    filter: false,
    selectableRows: "none",
    print: false,
    viewColumns: false,
    page: pagination.page,
    rowsPerPage: pagination.rowsPerPage,
    count: clientLocations.count,
    textLabels: {
      body: {
        noMatch: serverCall ? (
          <Skeleton variant="rect" />
        ) : (
          "Sorry, there is no data to display"
        )
      }
    },
    onChangeRowsPerPage: rowsPerPage =>
      setPagination({ ...pagination, rowsPerPage, page: 0 }),
    rowsPerPageOptions: [1, 10, 100, 500],
    download: false,
    serverSide: true,
    onTableChange: (action, tableState) => {
      const { page } = tableState;
      if (action === "changePage") setPagination({ ...pagination, page });
    },
    customToolbar: () => (
      <Grid container direction="row" justify="flex-end" spacing={3}>
        <TextField
          id="search"
          value={search.value}
          onChange={e => setSearch(e.target.value)}
          InputProps={{
            endAdornment: <SearchIcon />
          }}
        />
        <Button
          color="primary"
          variant="contained"
          style={{ marginLeft: "30px" }}
          onClick={() => setDialog(true)}>
          NEW LOCATION
        </Button>
      </Grid>
    )
  };

  const handleAddLocation = async () => {
    setServerCall(true);
    if (
      location.nickname === "" ||
      !location.latitude ||
      !location.longitude ||
      !location.placeId
    ) {
      dispatch(openErrorToast("Location and nickname needs to be filled"));
    } else {
      const searchClientId = accessor.clientId || clientId;
      if (!searchClientId) dispatch(openErrorToast("Choose a client"));
      else
        addLocationToClient(searchClientId, location).then(result =>
          getClientLocations().then(() => {
            setServerCall(false);
            setDialog(false);
            setLocation({
              nickname: "",
              name: "",
              latitude: null,
              longitude: null,
              placeId: null
            });
          })
        );
    }
  };

  useEffect(() => {
    getClientLocations();
  }, [
    clientId,
    pagination,
    debouncedSearch.length > 2 && debouncedSearch.length
  ]);

  return (
    <Grid>
      <MUIDataTable
        data={clientLocations.locations}
        columns={locationColumns}
        options={thisTableOptions}
      />
      <Dialog open={dialog} onClose={() => setDialog(false)} fullWidth>
        <DialogTitle>Add new Locations</DialogTitle>
        <DialogContent>
          <TextField
            label="nickname"
            style={{ width: "400px" }}
            value={location.nickname}
            onChange={e =>
              setLocation({ ...location, nickname: e.target.value })
            }
          />
          <AutoCompletePlaceSelector
            textFieldStyle={{ width: "400px" }}
            address={location.name}
            onChange={newAddress =>
              setLocation({ ...location, name: newAddress })
            }
            setAddressValue={values =>
              setLocation({ ...values, nickname: location.nickname })
            }
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleAddLocation}
            disabled={serverCall || !location.nickname || !location.name}
            color="primary">
            Add
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
}
