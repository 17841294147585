import React, { useState } from "react";
import { DateRangePicker, SingleDatePicker } from "react-dates";

import DateRangeButtons from "./DateRangeButtons";
import moment from "moment";

import "./index.css";

function CustomDatePicker(props) {
  const [focusedInput, setFocusedInput] = useState(
    props.type === "single" ? false : null
  );

  return props.type === "single" ? (
    <SingleDatePicker
      date={props.date}
      showClearDate
      placeholder={props.placeholder || "date"}
      onDateChange={date => props.onChange({ date })}
      focused={focusedInput}
      onFocusChange={({ focused }) => setFocusedInput(focused)}
      id="single-picker"
      numberOfMonths={1}
      small
      displayFormat={"DD MMM YYYY"}
    />
  ) : (
    <DateRangePicker
      startDate={props.startDate}
      minDate={moment("2019-12-01")}
      startDateId="start-date-picker"
      minimumNights={0}
      disabled={props.disabled}
      endDate={props.endDate}
      endDateId="end-date-picker"
      isOutsideRange={date =>
        date.isBefore(moment("2019-12-01")) ||
        date.isAfter(
          moment()
            .add(1, "month")
            .endOf("day")
        )
      }
      required
      small
      displayFormat={"DD MMM YYYY"}
      onDatesChange={({ startDate, endDate }) =>
        props.onChange({ startDate, endDate })
      }
      focusedInput={focusedInput}
      hideKeyboardShortcutsPanel
      onFocusChange={focused => setFocusedInput(focused)}
      calendarInfoPosition="after"
      renderCalendarInfo={() => (
        <DateRangeButtons
          onChange={({ startDate, endDate }) =>
            props.onChange({ startDate, endDate })
          }
        />
      )}
    />
  );
}

export default CustomDatePicker;
