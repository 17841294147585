import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { selectAll } from "d3";

function CompletionWidget(props) {
  const { completion = 0, errorRate = 0, id, width, height, className } = props;

  useEffect(() => {
    var svg = selectAll(`.${className}`);
    svg.html("");

    svg
      .append("text")
      .attr("x", 12)
      .attr("y", 16)
      .attr("font-size", "14px")
      .attr("fill", "#444444")
      .text(`${~~(completion * 100)} %`);

    svg
      .append("rect")
      .attr("x", 50)
      .attr("y", 4)
      .attr("height", 16)
      .attr("width", 340)
      .attr("fill", "#dadada");

    svg
      .append("rect")
      .attr("x", 50)
      .attr("y", 4)
      .attr("height", 16)
      .attr("fill", "#41b800")
      .transition()
      .attr("width", completion * 340)
      .duration(400);

    svg
      .append("text")
      .attr("x", 350 - errorRate * 340)
      .attr("y", 32)
      .attr("font-size", "14px")
      .attr("fill", "#444444")
      .text(`${Math.ceil(parseFloat(errorRate * 100))} %`);
    svg
      .append("rect")
      .attr("x", 390)
      .attr("y", 20)
      .attr("height", 16)
      .attr("fill", "#FF5252")
      .transition()
      .attr("x", 390 - errorRate * 340)
      .attr("width", errorRate * 340)
      .duration(400);
  }, [props]);
  return (
    <svg
      id={id || "completion-widget"}
      className={className || "completion-widget"}
      width={width || "400"}
      height={height || "40"}></svg>
  );
}

CompletionWidget.propTypes = {
  completion: PropTypes.number,
  errorRate: PropTypes.number,
  id: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  className: PropTypes.string
};

export default CompletionWidget;
