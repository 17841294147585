import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { openErrorToast } from "../../redux/actions";
import { Grid, TextField, FormControlLabel, Checkbox } from "@material-ui/core";

import Select from "react-select";
import { getLocationsForClient } from "../../Api";

import AutoCompletePlaceSelector from "../AutoCompletePlaceSelector";

export default function ClientLocationSelector(props) {
  const dispatch = useDispatch();
  const accessor = useSelector(state => state.accessor);
  const clientId = useSelector(state => state.clientId);

  const [clientLocations, setClientLocations] = useState([]);
  const [showCustom, setShowCustom] = useState(false);
  const [location, setLocation] = useState({
    nickname: "",
    name: "",
    latitude: null,
    longitude: null,
    placeId: null,
    liftAvailability: false,
    floorNumber: "",
    buildingName: ""
  });
  const getThisClientLocations = async () => {
    const searchClientId = accessor.clientId || props.clientId || clientId;
    if (!searchClientId && !props.optional)
      dispatch(openErrorToast("Choose a client"));
    else {
      const { locations } = await getLocationsForClient(searchClientId);
      setClientLocations(locations);
    }
  };

  useEffect(() => {
    if (location.name !== "") props.onChange(location);
  }, [location]);

  useEffect(() => {
    getThisClientLocations();
  }, [clientId]);

  const locationOptions = [
    ...(props.custom ? [{ value: 0, label: "Custom" }] : []),
    ...clientLocations.map(loc => ({
      value: loc.nickname,
      label: loc.nickname
    }))
  ];

  const customStyles = {
    container: styles => ({ ...styles, height: "40px" }),
    control: styles => ({ ...styles, height: "40px", borderColor: "#dbdbdb" }),
    menu: styles => ({ ...styles, width: "300px", zIndex: "1000" }),
    menuList: styles => ({ ...styles, width: "300px", zIndex: "1000" }),
    option: (styles, state) => ({
      ...styles,
      background: state.isSelected
        ? "#439fd9"
        : state.isFocused
        ? "#c7e9ff"
        : "#FFFFFF"
    })
  };
  // If customizable, display as grid, since other textfields are there
  return !props.custom ? (
    <Select
      styles={customStyles}
      options={locationOptions}
      className="dropdown"
      isClearable={true}
      isDisabled={props.isDisabled}
      placeholder={props.label}
      onChange={e =>
        props.onChange(
          e && e.value
            ? clientLocations.find(location => location.nickname === e.value)
            : ""
        )
      }
    />
  ) : (
    <Grid container direction="row" justify="flex-start" alignItems="flex-end">
      <Select
        styles={customStyles}
        options={locationOptions}
        className="dropdown"
        isClearable={true}
        placeholder={props.label}
        onChange={e => {
          if (e && e.value === 0) setShowCustom(true);
          else {
            // e has some value
            setShowCustom(false);
            props.onChange(
              e && e.value
                ? clientLocations.find(
                    location => location.nickname === e.value
                  )
                : ""
            );
          }
        }}
      />
      {showCustom && (
        <AutoCompletePlaceSelector
          textFieldStyle={{ width: "400px", marginLeft: "10px" }}
          address={location.name}
          onChange={newAddress =>
            setLocation({ ...location, name: newAddress })
          }
          setAddressValue={values => setLocation({ ...location, ...values })}
        />
      )}
      {showCustom && (
        <TextField
          required
          style={{ marginLeft: "10px", width: "200px" }}
          label="Building Name/Door"
          value={location.buildingName}
          onChange={e =>
            setLocation({ ...location, buildingName: e.target.value })
          }
        />
      )}
      {showCustom && (
        <TextField
          required
          style={{ margin: "0px 10px", width: "120px" }}
          type="number"
          label="Floor Number"
          value={location.floorNumber}
          onChange={e => {
            setLocation({ ...location, floorNumber: +e.target.value });
          }}
        />
      )}
      {showCustom && location.floorNumber > 0 && (
        <FormControlLabel
          control={
            <Checkbox
              checked={location.liftAvailability}
              value={location.liftAvailability}
              onChange={e =>
                setLocation({
                  ...location,
                  liftAvailability: !location.liftAvailability
                })
              }
            />
          }
          label="Service Lift ?"
        />
      )}
    </Grid>
  );
}
