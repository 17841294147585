import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import {
  Grid,
  Chip,
  Button,
  TextField,
  Link,
  Typography
} from "@material-ui/core";
import Header from "../../components/Header";

import MaterialDataTable from "../../components/MaterialDataTable";

import SearchIcon from "@material-ui/icons/Search";
import { getProjects } from "../../Api";

import ClientSelector from "../../components/ClientSelector";
import Joyride, { STATUS } from "react-joyride";
import { makeStyles } from "@material-ui/styles";
import clsx from "clsx";

import useDebounceState from "../../customHooks/useDebounceState";

import { joyrideStyles } from "../../constants";

const statusMap = { 1: "requested", 2: "accepted", 8: "completed" };

const steps = [
  {
    target: ".table-row",
    disableBeacon: true,
    content:
      "This is a list of projects. Click to go to the respective project."
  },
  {
    target: ".search-project",
    content: "Type the project Id to search for a project here"
  },
  {
    target: ".new-project-button",
    content: "Click here to create a new project for logistics"
  },
  {
    target: ".client-selector",
    content: "Click here to filter by client"
  }
];

const useStyles = makeStyles(theme => ({
  circle: {
    border: "1px solid #ebebeb",
    borderRadius: "50%",
    margin: "3px",
    height: "18px",
    width: "18px",
    float: "left"
  },
  requested: {
    backgroundColor: "#FF9D4F"
  },
  accepted: {
    backgroundColor: "#FFCF00"
  },
  completed: {
    backgroundColor: "#43d95e"
  },
  chip: {
    margin: 3
  },
  tag: {
    border: "1px solid #979797"
  },
  legend: {
    marginTop: 30
  },
  legendText: {
    fontWeight: "bold"
  },
  legendCategoryText: {
    fontStyle: "italic",
    fontSize: 12,
    fontWeight: 300
  }
}));

export default function ProjectsPage(props) {
  const classes = useStyles();
  const accessor = useSelector(state => state.accessor);
  const reduxClientId = useSelector(state => state.clientId);

  const [data, setData] = useState({ projects: [], count: 1 });
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({ page: 0, rowsPerPage: 25 });
  const [search, setSearch] = useState("");
  const debouncedSearch = useDebounceState({ value: search, delay: 1000 });

  const clientId = accessor.clientId || reduxClientId;
  const columns = [
    { name: "id", label: "ULI ID", options: { display: false, filter: false } },
    { name: "projectRefId", label: "Project Reference Id" },
    { name: "userDefinedFields", label: "Project data" },
    {
      name: "opRefId",
      label: "Operational reference Id",
      options: { display: false, filter: false }
    },

    { name: "tags", label: "Tags" },
    { name: "phases", label: "Phases" },
    {
      name: "articleListLink",
      label: "Article List Link",
      options: { display: false, filter: false }
    },
    {
      name: "clientName",
      label: "Client Name",
      options: { display: !clientId, filter: false }
    }
  ];

  useEffect(() => {
    setPagination({ ...pagination, page: 0 });
  }, [debouncedSearch]);

  useEffect(() => {
    retrieveData();
    const reset10minutes = setInterval(() => {
      retrieveData();
    }, 600000);
    return () => clearInterval(reset10minutes);
  }, [
    pagination,
    debouncedSearch.length > 2 && debouncedSearch.length,
    clientId
  ]);

  const retrieveData = async () => {
    setLoading(true);
    const { projects, count } = await getProjects(
      pagination.rowsPerPage,
      pagination.page,
      search,
      clientId
    );
    setLoading(false);
    setData({ projects, count });
  };

  const handleAddProject = () => {
    props.history.push("/projects/new");
  };

  const projectsPageTutorial = window.localStorage.getItem(
    "projects-page-tutorial"
  );
  return (
    <Grid className="mainContainer">
      <Header title="All Projects" />
      {!projectsPageTutorial && (
        <Joyride
          steps={steps}
          continuous={true}
          scrollToFirstStep={true}
          showProgress={true}
          styles={{
            options: joyrideStyles
          }}
          callback={({ status }) => {
            if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
              window.localStorage.setItem("projects-page-tutorial", true);
            }
          }}
        />
      )}
      <Grid
        container
        direction="row"
        justify="flex-start"
        alignItems="flex-end">
        <ClientSelector />
      </Grid>
      <Grid container direction="row" justify="flex-end" alignItems="flex-end">
        <TextField
          style={{ marginLeft: "30px" }}
          value={search}
          autoFocus
          className="search-project"
          onChange={e => setSearch(e.target.value)}
          InputProps={{
            endAdornment: <SearchIcon style={{ color: "#9b9b9b" }} />
          }}
        />
        <Button
          color="primary"
          className="new-project-button"
          variant="contained"
          style={{ marginLeft: "30px" }}
          onClick={handleAddProject}>
          NEW PROJECT
        </Button>
      </Grid>

      <MaterialDataTable
        data={data.projects}
        columns={columns}
        busy={loading}
        pagination={{
          page: pagination.page,
          rowsPerPage: pagination.rowsPerPage,
          rowsPerPageOptions: [1, 10, 25, 100],
          total: data.count,
          onChange: payload => setPagination(payload)
        }}
        options={{
          customRowRender: row => (
            <tr
              className="table-row"
              key={row.id}
              onClick={() => props.history.push(`/project/${row.id}`)}>
              <td>
                <b>
                  <Link href={`/project/${row.id}`}>{row.projectRefId}</Link>
                </b>
              </td>
              <td>
                {row.userDefinedFields &&
                  Object.entries(JSON.parse(row.userDefinedFields)).map(
                    ([key, value]) => (
                      <Grid key={key}>
                        <Typography variant="caption">{key}</Typography>
                        <Typography variant="body1">{value}</Typography>
                      </Grid>
                    )
                  )}
              </td>
              <td>
                {row.tags.map((tag, i) => (
                  <Chip
                    label={tag}
                    key={i}
                    className={clsx([classes.tag, classes.chip])}
                  />
                ))}
              </td>
              <td>
                {row.phases.map((phase, i) => (
                  <Chip
                    key={i}
                    label={phase.name}
                    className={clsx([
                      classes[statusMap[phase.status]],
                      classes.chip
                    ])}
                  />
                ))}
              </td>
              {!clientId && <td>{row.clientName}</td>}
            </tr>
          )
        }}
      />
      <Grid
        direction="row"
        container
        justify="center"
        alignItems="center"
        className={classes.legend}
        spacing={2}>
        <Grid item>Legend: </Grid>
        <Grid item>
          <div className={`${classes.circle} ${classes.requested}`}></div>
          <span className={classes.legendCategoryText}>Requested</span>
        </Grid>
        <Grid item>
          <div className={`${classes.circle} ${classes.accepted}`}></div>
          <span className={classes.legendCategoryText}>Accepted</span>
        </Grid>
        <Grid item>
          <div className={`${classes.circle} ${classes.completed}`}></div>
          <span className={classes.legendCategoryText}>Completed</span>
        </Grid>
      </Grid>
    </Grid>
  );
}
