import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { Grid, Tabs, Tab, Badge, Link } from "@material-ui/core";

import { Header } from "../../components";

import { getProjectPhases, getProjectPhaseCount } from "../../Api";
import { displayTime } from "../../constants";

import * as moment from "moment";

import ClientSelector from "../../components/ClientSelector";
import CustomDatePicker from "../../components/CustomDatePicker";
import ClientLocationSelector from "../../components/ClientLocationSelector";
import Joyride, { STATUS } from "react-joyride";
import MaterialDataTable from "../../components/MaterialDataTable";
import { makeStyles } from "@material-ui/styles";
import useBlinkPageTitle from "../../customHooks/useBlinkDocumentTitle";

import { joyrideStyles } from "../../constants";

const title = document.title;

const columns = [
  {
    name: "projectRefId",
    label: "Project Ref Id",
    options: { align: "center" }
  },
  { name: "userDefinedFields", label: "Data", options: { align: "center" } },
  {
    name: "id",
    label: "id",
    options: { sortDirection: "desc", display: false }
  },
  { name: "phaseName", label: "Phase" },
  { name: "phaseData", label: "phase data" },
  { name: "data", label: "Data", options: { display: false } },
  { name: "projectId", label: "Project Id", options: { display: false } },
  { name: "phaseCreationTime", label: "Created At" },
  { name: "opsRefId", label: "Ops Id" },
  { name: "clientName", label: "Client" }
];

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  }
}));

export default function OpsCenter(props) {
  const classes = useStyles();
  const [selectedTab, setSelectedTab] = useState(1);
  const [data, setData] = useState({ phases: [], count: 0 });
  const [badgeValues, setBadgeValues] = useState([
    { status: 1, count: 0 },
    { status: 2, count: 0 },
    { status: 8, count: 0 }
  ]);
  const [loading, setLoading] = useState(false);

  const clientId = useSelector(state => state.clientId);
  const [filters, setFilters] = useState({
    date: "",
    startDate: moment().subtract(1, "month"),
    endDate: moment().add(1, "month"),
    location: {},
    phaseId: null
  });

  const [pagination, setPagination] = useState({ page: 0, rowsPerPage: 25 });

  const getBadgeCount = async () => {
    const { statusCount } = await getProjectPhaseCount();
    setBadgeValues(statusCount);
  };

  useBlinkPageTitle({
    duration: 1000,
    prefix: `${
      badgeValues &&
      badgeValues.find(value => value.status === selectedTab) &&
      badgeValues.find(value => value.status === selectedTab).count > 0
        ? `(${badgeValues.find(value => value.status === selectedTab).count})`
        : ``
    } `,
    title
  });

  const getProjectPhasesForOps = async () => {
    setLoading(true);
    if (filters.startDate && filters.endDate) {
      const { phases, count } = await getProjectPhases({
        pageSize: pagination.rowsPerPage,
        pageNumber: pagination.page,
        status: selectedTab,
        location: filters.location.nickname,
        startDate: filters.startDate.format("YYYY-MM-DD"),
        endDate: filters.endDate.format("YYYY-MM-DD"),
        ...(clientId ? { clientId } : {})
      });

      const projectPhases = phases.map(phase => {
        const { data, ...rest } = phase;
        return {
          ...rest,
          data: Object.entries(phase.phaseData).map(([key, values]) => {
            let object = {};
            object[`${key}`] = `${values.date ||
              values.number ||
              values.string ||
              values.nickname ||
              values.name} 
                ${values.floorNumber ? `Floor: ${values.floorNumber}` : ``} ${
              values.floorNumber && values.floorNumber > 0
                ? values.liftAvailability
                  ? `Lift Available`
                  : `NO LIFT`
                : ``
            }`;
            return object;
          })
        };
      });
      setData({ phases: projectPhases, count });
    }

    setLoading(false);
  };
  useEffect(() => {
    getBadgeCount();
    getProjectPhasesForOps();
    const reset10minutes = setInterval(() => {
      getBadgeCount();
      getProjectPhasesForOps();
    }, 600000);
    return () => {
      clearInterval(reset10minutes);
    };
  }, [filters, selectedTab, pagination, clientId]);

  const steps = [
    {
      target: ".status-tab-selector",
      disableBeacon: true,
      content: "The phases to be planned for are shown by status here. "
    },
    {
      target: ".table-row",
      content:
        "Click here to go to a project - Look for the phase in the list, put in the center of the page"
    },
    {
      target: ".opsRef",
      content:
        "When you have linked to our internal order id, it will display here"
    }
  ];

  const opsCenterTutorial = window.localStorage.getItem("ops-center-tutorial");

  return (
    <Grid className="mainContainer">
      <Header title="Ops Center" />
      {!opsCenterTutorial && (
        <Joyride
          steps={steps}
          continuous={true}
          styles={{ options: joyrideStyles }}
          callback={({ status }) => {
            if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
              window.localStorage.setItem("ops-center-tutorial", true);
            }
          }}
        />
      )}
      <Grid container direction="column" className={classes.root}>
        <Grid
          container
          direction="row"
          spacing={2}
          justify="flex-start"
          alignItems="center">
          <Grid item>
            <Tabs
              className="status-tab-selector"
              value={selectedTab}
              indicatorColor="primary"
              textColor="primary"
              onChange={(e, newValue) => setSelectedTab(newValue)}>
              <Tab
                value={1}
                label={
                  <Badge
                    badgeContent={
                      badgeValues.find(b => b.status === 1)
                        ? badgeValues.find(b => b.status === 1).count
                        : 0
                    }
                    color="error">
                    Requested
                  </Badge>
                }
              />
              <Tab
                value={2}
                label={`Accepted (${
                  badgeValues.find(b => b.status === 2)
                    ? badgeValues.find(b => b.status === 2).count
                    : 0
                })`}
              />
              <Tab value={8} label="Completed" />
            </Tabs>
          </Grid>
          <Grid item>
            <ClientSelector />
          </Grid>
          <Grid item>
            <CustomDatePicker
              startDate={filters.startDate}
              endDate={filters.endDate}
              onChange={({ startDate, endDate }) => {
                setFilters({ ...filters, startDate, endDate });
              }}
            />
          </Grid>

          {clientId && (
            <Grid item>
              <ClientLocationSelector
                custom={false}
                optional={true}
                label={clientId ? `Filter Location` : ` Select client First`}
                onChange={location => setFilters({ ...filters, location })}
              />
            </Grid>
          )}
        </Grid>

        <MaterialDataTable
          data={data.phases}
          columns={columns}
          busy={loading}
          pagination={{
            rowsPerPageOptions: [1, 10, 25, 100],
            page: pagination.page,
            rowsPerPage: pagination.rowsPerPage,
            total: data.count,
            onChange: payload => setPagination(payload)
          }}
          options={{
            customRowRender: (row, rowIndex) => (
              <tr
                key={rowIndex}
                className="table-row"
                onClick={e => {
                  window.open(
                    e.target.className.search("opsRef") !== -1 && row.opsRefId
                      ? `https://app.pikkol.com/admin/orders/${row.opsRefId}`
                      : `/project/${row.projectId}`,
                    "_self"
                  );
                }}
                style={{ fontSize: "0.9em" }}>
                <td align="center">
                  <b>
                    <Link href={`/project/${row.projectId}`} target="_self">
                      {row.projectRefId}
                    </Link>
                  </b>
                </td>
                <td>
                  {row.userDefinedFields &&
                    Object.entries(JSON.parse(row.userDefinedFields)).map(
                      ([key, value]) => (
                        <>
                          {key}: {value} <br />
                        </>
                      )
                    )}
                </td>
                <td>{row.phaseName}</td>

                <td align="left" style={{ padding: "5px" }}>
                  {row.phaseData.map((dataPoint, i) => {
                    const [[pointLabel, pointData]] = Object.entries(dataPoint);
                    return (
                      <div key={i}>
                        {pointLabel} :{" "}
                        {pointData.date
                          ? moment(pointData.date).format("DD MMM YY")
                          : pointData.nickname ||
                            pointData.number ||
                            pointData.string ||
                            pointData.name}
                      </div>
                    );
                  })}
                </td>
                <td>{displayTime(row.phaseCreationTime)}</td>
                <td
                  className="opsRef"
                  style={{
                    ...(row.status !== 1 && !row.opsRefId
                      ? { backgroundColor: "#FF5252" }
                      : {})
                  }}>
                  {row.opsRefId && (
                    <Link
                      href={`https://app.pikkol.com/admin/orders/${row.opsRefId}`}
                      target="_blank">
                      {row.opsRefId}
                    </Link>
                  )}
                </td>
                <td>{row.clientName}</td>
              </tr>
            )
          }}
        />
      </Grid>
    </Grid>
  );
}
