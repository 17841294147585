import * as moment from "moment";

export const locationColumns = [
  { name: "nickname", label: "ShortName" },
  { name: "name", label: "Address" },
  { name: "latitude", label: "Latitude" },
  { name: "longitude", label: "Longitude" },
  { name: "placeId", label: "place ID" }
];

export const tableOptions = {
  download: false,
  print: false,
  selectableRows: "none",
  viewColumns: false,
  filter: false,
  search: false
};

export const pocColumns = [
  { name: "name", label: "name" },
  { name: "phoneNumber", label: "Phone Number" },
  { name: "email", label: "Email address" }
];

export const companyColumns = [
  { name: "companyName", label: "Company Name" },
  { name: "panNumber", label: "PAN Number" }
];

export const entityColumns = [
  { name: "billableEntityName", label: "GST Entity Name" },
  { name: "gstNumber", label: "GST Number" },
  { name: "companyName", label: "Company Name" },
  { name: "stateName", label: "State Name" }
];

export const timeslotColumns = [
  { name: "name", label: "Timeslot name" },
  { name: "startTime", label: "Start time" },
  { name: "endTime", label: "End time" }
];

export const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const phoneRegex = /^\d{10}$/;
export const timeRegex = /(?:[01]\d|2[0123]):(?:[012345]\d):(?:[012345]\d)/;

export function displayTime(timeString) {
  return moment(timeString).isAfter(moment().startOf("day"))
    ? `${moment(timeString).format("hh:mm A")} (${moment(
        timeString
      ).fromNow()})`
    : `${moment(timeString).format("DD MMM YY hh:mm A")}`;
}

export const joyrideStyles = {
  primaryColor: "#439fd9"
};

export const flattenObject = ob =>
  Object.entries(ob).reduce(
    (acc, [key, value]) =>
      key === null || value === null
        ? acc
        : typeof value === "object"
        ? { ...acc, ...flattenObject(value) }
        : { ...acc, [key]: value },
    {}
  );

export const arrayToCSV = (data, fileName = "abc") => {
  const keys = Object.keys(flattenObject(data[0]));

  const csvBlob =
    keys.join(`,`) +
    `
  ` +
    data
      .map(row => flattenObject(row))
      .map(row => keys.map(key => `"${row[key]}"` || `""`).join(`,`)).join(`
  `);
  const encodedUri = encodeURI(`data:text/csv;charset=utf-8,${csvBlob}`);
  var link = document.createElement("a");
  link.href = encodedUri;
  link.download = `${fileName}.csv`;
  link.click();
};
