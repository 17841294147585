import React from "react";
import { Grid } from "@material-ui/core";
import Header from "../../components/Header";

export default function ErrorPage(props) {
  return (
    <Grid className="mainContainer">
      <Header title="ERROR Page" />
      <h1> 404 ERROR</h1>
      <p style={{ margin: "10px" }}>
        Cannot find this page on this server. Please press back button on the
        browser and check the url
      </p>
    </Grid>
  );
}
