import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { auth } from "firebase/app";

import SearchIcon from "@material-ui/icons/Search";
import CheckIcon from "@material-ui/icons/Check";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import {
  Grid,
  Button,
  DialogActions,
  DialogTitle,
  Dialog,
  DialogContent,
  Chip,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  CircularProgress
} from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";

import { REACT_APP_BASE_URL } from "../../config/constants";

import { addGroupToAccount, removeGroupFromAccount } from "../../Api";

import {
  getAccountsForClientId,
  addAccountToClient,
  activateAccount,
  deactivateAccount
} from "../../Api/accounts";

import MUIDataTable from "mui-datatables";

import { tableOptions, emailRegex, phoneRegex } from "../../constants";
import { openErrorToast, openSuccessToast } from "../../redux/actions";
import useDebounceState from "../../customHooks/useDebounceState";

const accountColumns = [
  { name: "id", label: "id", options: { display: false } },
  { name: "name", label: "Account Name" },
  { name: "email", label: "Email (verify?)" },
  { name: "phoneNumber", label: "Phone Number" },
  { name: "groups", label: "Groups" },
  { name: "verifiedFlag", label: "verify", options: { display: false } },
  { name: "activeFlag", label: "Active?" }
];

export default function ClientAccountHandler() {
  const dispatch = useDispatch();
  const accessor = useSelector(state => state.accessor);
  const clientId = useSelector(state => state.clientId);
  const [serverCall, setServerCall] = useState(false);
  const [dialog, setDialog] = useState(false);
  const [editAccountDialog, setEditAccountDialog] = useState({
    open: false,
    account: []
  });
  const [pagination, setPagination] = useState({ page: 0, rowsPerPage: 10 });
  const [search, setSearch] = useState("");
  const debouncedSearch = useDebounceState({ value: search, delay: 1000 });

  const [newUser, setNewUser] = useState({
    name: "",
    email: "",
    phoneNumber: "",
    groups: []
  });

  const clientGroups = [
    { value: 40, name: "Client Admins" },
    { value: 50, name: "Client Managers" },
    { value: 60, name: "Client Users" }
  ];

  const [clientAccounts, setClientAccounts] = useState({
    accounts: [],
    count: 0
  });
  const thisTableOptions = {
    ...tableOptions,
    onTableChange: (action, tableState) => {
      const { page } = tableState;
      if (action === "changePage") setPagination({ ...pagination, page });
    },
    textLabels: {
      body: {
        noMatch: serverCall ? (
          <Skeleton variant="rect" />
        ) : (
          "Sorry, there is no data to display"
        )
      }
    },
    serverSide: true,
    rowsPerPageOptions: [1, 10, 25, 100],
    count: clientAccounts.count,
    customToolbar: () => (
      <Grid container direction="row" justify="flex-end" spacing={3}>
        <TextField
          id="search"
          value={search.value}
          onChange={e => setSearch(e.target.value)}
          InputProps={{
            endAdornment: <SearchIcon />
          }}
        />
        <Button
          color="primary"
          variant="contained"
          style={{ marginLeft: "30px" }}
          onClick={() => setDialog(true)}>
          NEW ACCOUNT
        </Button>
      </Grid>
    ),
    customRowRender: rowData => (
      <tr
        key={rowData[0]}
        className="table-row"
        onClick={() => {
          setEditAccountDialog({ open: true, account: rowData });
        }}>
        <td>{rowData[1]}</td>
        <td>
          {rowData[2]}
          {rowData[5] === 1 && (
            <CheckCircleIcon fontSize="small" color="primary" />
          )}
        </td>
        <td>{rowData[3]}</td>
        <td>
          {rowData[4].map(group => (
            <Chip key={group} label={group} />
          ))}
        </td>
        <td>{rowData[6] === 1 && <CheckIcon />}</td>
      </tr>
    ),
    onChangeRowsPerPage: rowsPerPage =>
      setPagination({ ...pagination, rowsPerPage })
  };

  const getClientAccounts = async () => {
    const searchClientId = accessor.clientId || clientId;
    if (!clientId) dispatch(openErrorToast("Choose a client"));
    else {
      const accounts = await getAccountsForClientId(
        searchClientId,
        pagination.rowsPerPage,
        pagination.page,
        search
      );
      setClientAccounts(accounts);
    }
  };

  const handleAddAccount = async () => {
    setServerCall(true);
    if (
      newUser.name === "" ||
      !emailRegex.test(newUser.email) ||
      !phoneRegex.test(newUser.phoneNumber) ||
      newUser.groups.length === 0
    ) {
    } else {
      const searchClientId = accessor.clientId || clientId;
      if (!clientId) dispatch(openErrorToast("Choose a client"));
      else
        addAccountToClient(searchClientId, newUser)
          .then(result =>
            auth()
              .sendSignInLinkToEmail(newUser.email, {
                url: REACT_APP_BASE_URL,
                handleCodeInApp: true
              })
              .then(() => {
                setServerCall(false);
                getClientAccounts();
                setDialog(false);
                dispatch(openSuccessToast("Account created"));
              })
          )
          .catch(({ err }) => {
            dispatch(openErrorToast(err));
          });
    }
  };

  useEffect(() => {
    getClientAccounts();
  }, [
    clientId,
    pagination,
    debouncedSearch.length > 2 && debouncedSearch.length,
    editAccountDialog
  ]);

  return (
    <Grid>
      <MUIDataTable
        data={clientAccounts.accounts}
        columns={accountColumns}
        options={thisTableOptions}
      />
      <Dialog open={dialog} onClose={() => setDialog(false)} fullWidth>
        <DialogTitle>Add New Accounts</DialogTitle>
        <DialogContent>
          <Grid container direction="column" spacing={3}>
            <TextField
              label="Name"
              value={newUser.name}
              className="fullWidthMax400"
              onChange={e => setNewUser({ ...newUser, name: e.target.value })}
            />
            <TextField
              label="Email"
              value={newUser.email}
              className="fullWidthMax400"
              onChange={e => setNewUser({ ...newUser, email: e.target.value })}
            />
            <TextField
              label="Phone Number"
              value={newUser.phoneNumber}
              className="fullWidthMax400"
              onChange={e =>
                setNewUser({ ...newUser, phoneNumber: e.target.value })
              }
            />
            <FormControl>
              <InputLabel id="groups">Groups</InputLabel>
              <Select
                value=""
                onChange={e => {
                  setNewUser({
                    ...newUser,
                    groups: [...newUser.groups, e.target.value.value]
                  });
                }}>
                {clientGroups
                  .filter(
                    cGroup =>
                      !newUser.groups.some(uGroup => uGroup === cGroup.value)
                  )
                  .map(group => (
                    <MenuItem key={group.value} value={group}>
                      {group.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>

            {newUser.groups.map((group, index) => (
              <Chip
                key={group}
                style={{ width: "400px", margin: "5px" }}
                label={clientGroups.find(cGroup => cGroup.value === group).name}
                onDelete={() =>
                  setNewUser({
                    ...newUser,
                    groups: newUser.groups.filter(
                      (_, groupIndex) => groupIndex !== index
                    )
                  })
                }
              />
            ))}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            disabled={serverCall}
            onClick={handleAddAccount}>
            Create
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={editAccountDialog.open}
        fullWidth
        onClose={() => setEditAccountDialog({ open: false, account: [] })}>
        <DialogTitle>Add Groups to User</DialogTitle>
        <DialogContent>
          <p>Name: {editAccountDialog.account[1]}</p>
          <p>Email: {editAccountDialog.account[2]}</p>
          <p>Phone: {editAccountDialog.account[3]}</p>
          <Grid>
            {serverCall && <CircularProgress />}
            <Grid>
              {editAccountDialog.account[4] &&
                editAccountDialog.account[4].map(group => (
                  <Chip
                    key={group}
                    label={group}
                    onDelete={async () => {
                      const removeGroup = clientGroups.find(
                        cG => cG.name === group
                      ).value;
                      setServerCall(true);
                      await removeGroupFromAccount(
                        editAccountDialog.account[0],
                        removeGroup
                      );
                      setServerCall(false);
                      getClientAccounts();
                      setEditAccountDialog({ open: false, account: [] });
                    }}
                  />
                ))}
            </Grid>
            <Grid>
              <Select
                onChange={async e => {
                  const groupToAdd = e.target.value.value;
                  setServerCall(true);
                  await addGroupToAccount(
                    editAccountDialog.account[0],
                    groupToAdd
                  );
                  setServerCall(false);
                  getClientAccounts();
                  setEditAccountDialog({ open: false, account: {} });
                }}>
                {clientGroups
                  .filter(
                    cGroup =>
                      editAccountDialog.account[4] &&
                      !editAccountDialog.account[4].some(
                        uGroup => uGroup === cGroup.name
                      )
                  )
                  .map(group => (
                    <MenuItem key={group.value} value={group}>
                      {group.name}
                    </MenuItem>
                  ))}
              </Select>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              auth().sendSignInLinkToEmail(editAccountDialog.account[2], {
                url: REACT_APP_BASE_URL,
                handleCodeInApp: true
              });
            }}>
            Send Sign In link
          </Button>
          <Button
            color={editAccountDialog.account[6] === 0 ? "primary" : "secondary"}
            onClick={() => {
              editAccountDialog.account[6] === 0
                ? activateAccount(editAccountDialog.account[0])
                : deactivateAccount(editAccountDialog.account[0]);
              setEditAccountDialog({ open: false, account: [] });
            }}>
            {editAccountDialog.account[6] === 1 ? `DEACTIVATE` : `ACTIVATE`}
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
}
