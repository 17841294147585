import { getToken, baseUrl } from "./index";
import fetch from "isomorphic-fetch";

/**
 * Calls create_order_uli API on pikkol_production from backend
 * @param {object} order
 */
export const createOrderInAdmin = async order =>
  fetch(`${baseUrl}/admin/order`, {
    method: "POST",
    headers: {
      Authorization: `bearer ${await getToken()}`,
      "Content-Type": "application/json"
    },
    body: JSON.stringify({ order })
  }).then(response => response.json());
