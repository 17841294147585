import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { openErrorToast } from "../../redux/actions";

import Select from "react-select";
import { getTimeslotsForClient } from "../../Api";

export default function ClientTimeSlotSelector(props) {
  const dispatch = useDispatch();
  const accessor = useSelector(state => state.accessor);
  const clientId = useSelector(state => state.clientId);

  const [clientTimeSlots, setClientTimeslots] = useState([]);

  const getThisClientTimeslots = async () => {
    const searchClientId = accessor.clientId || props.clientId || clientId;
    if (!searchClientId) dispatch(openErrorToast("Choose a client"));
    else {
      const { timeSlots } = await getTimeslotsForClient(searchClientId);
      setClientTimeslots(timeSlots);
    }
  };
  useEffect(() => {
    getThisClientTimeslots();
  }, []);

  const timeSlotOptions = clientTimeSlots.map(slot => ({
    value: slot.name,
    label: slot.name
  }));

  const customStyles = {
    container: styles => ({ ...styles }),
    menu: styles => ({ ...styles, width: "300px", zIndex: "1000" }),
    menuList: styles => ({ ...styles, width: "300px", zIndex: "1000" }),
    option: (styles, state) => ({
      ...styles,
      background: state.isSelected
        ? "#439fd9"
        : state.isFocused
        ? "#c7e9ff"
        : "#FFFFFF"
    })
  };
  return (
    <Select
      className="dropdown"
      placeholder={props.label}
      options={timeSlotOptions}
      styles={customStyles}
      onChange={e =>
        props.onChange(clientTimeSlots.find(slot => slot.name === e.value))
      }
    />
  );
}
