import { getToken, baseUrl } from "./index";
import fetch from "isomorphic-fetch";

/**
 * Get List of Projects as per parameters
 * @param {number} clientId
 * @param {number} pageSize
 * @param {number} pageNumber
 * @param {string} projectRefId
 */
export const getProjects = async (
  pageSize = 20,
  pageNumber = 1,
  search,
  clientId
) =>
  fetch(
    `${baseUrl}/projects?pageSize=${pageSize}&pageNumber=${pageNumber}${
      clientId ? `&clientId=${clientId}` : ``
    }${search && search.length > 2 && `&search=${search}`}`,
    {
      headers: {
        Authorization: `bearer ${await getToken()}`
      }
    }
  ).then(response => response.json());
/**
 * Get Phases for a project
 * @param {number} projectId
 */
export const getPhases = async projectId =>
  fetch(`${baseUrl}/projects/${projectId}/phases`, {
    headers: {
      Authorization: `bearer ${await getToken()}`
    }
  }).then(response => response.json());

/**
 * Get project details for project by project Id
 * @param {number} projectId
 */
export const getProject = async projectId =>
  fetch(`${baseUrl}/projects/${projectId}`, {
    headers: {
      Authorization: `bearer ${await getToken()}`
    }
  }).then(response => response.json());
/**
 * Get project details by project Reference Id
 */
export const getProjectByRefId = async (clientId, refId) =>
  fetch(`${baseUrl}/projects?projectRefId=${refId}`, {
    headers: {
      Authorization: `bearer ${await getToken()}`
    }
  }).then(response => response.json());

/**
 *
 * @param {number} clientId
 * @param {string} projectRefId
 * @param {string[]} tags
 */
export const createProject = async ({
  clientId,
  projectRefId,
  tags,
  udfData
}) =>
  await fetch(`${baseUrl}/projects/`, {
    method: "POST",
    headers: {
      Authorization: `bearer ${await getToken()}`,
      "Content-Type": "application/json"
    },
    body: JSON.stringify({ projectRefId, tags, clientId, udfData })
  }).then(response => response.json());
/**
 * UPDATE Project by ref Id
 * @param {number} projectId
 * @param {number} opRefId
 */

export const updateProject = async (projectId, parameters) =>
  await fetch(`${baseUrl}/projects/${projectId}`, {
    method: "PATCH",
    headers: {
      Authorization: `bearer ${await getToken()}`,
      "Content-Type": "application/json"
    },
    body: JSON.stringify(parameters)
  }).then(response => response.json());

export const getProjectsUDF = async clientId =>
  await fetch(`${baseUrl}/projects/udf_schema?clientId=${clientId}`, {
    headers: {
      Authorization: `bearer ${await getToken()}`
    }
  }).then(response => response.json());
