import { getToken, baseUrl } from "./index";
import fetch from "isomorphic-fetch";

/**
 * Get the list of clients the current user has access to
 * @param {number} clientId
 */
export const getClients = async () =>
  fetch(`${baseUrl}/clients/`, {
    headers: {
      Authorization: `bearer ${await getToken()}`
    }
  }).then(response => response.json());

/**
 * Get master data for all phases, given the client Id
 * @param {number} clientId
 */
export const getPhasesForClient = async clientId =>
  fetch(`${baseUrl}/clients/${clientId}/phases`, {
    headers: {
      Authorization: `bearer ${await getToken()}`
    }
  }).then(response => response.json());

/**
 * Get Locations from client_settings, for a client
 * @param {number} clientId
 */
export const getLocationsForClient = async (
  clientId,
  pageSize,
  pageNumber,
  search
) =>
  fetch(
    `${baseUrl}/clients/${clientId}/locations?pageSize=${pageSize}&pageNumber=${pageNumber}${
      search && search.length > 2 ? `&search=${search}` : ``
    }`,
    {
      headers: {
        Authorization: `bearer ${await getToken()}`
      }
    }
  ).then(response => response.json());

/**
 * Add a location to client_settings, for the given client
 * @param {number} clientId
 * @param {object} location
 */
export const addLocationToClient = async (clientId, location) =>
  fetch(`${baseUrl}/clients/${clientId}/location`, {
    method: "POST",
    headers: {
      Authorization: `bearer ${await getToken()}`,
      "Content-Type": "application/json"
    },
    body: JSON.stringify({ location })
  }).then(response => response.json());

/**
 * Get POCs for a client
 * @param {number} clientId
 */
export const getPOCsForClient = async (
  clientId,
  pageSize,
  pageNumber,
  search
) =>
  fetch(
    `${baseUrl}/clients/${clientId}/pocs?pageSize=${pageSize}&pageNumber=${pageNumber}${
      search ? `&search=${search}` : ``
    }`,
    {
      headers: {
        Authorization: `bearer ${await getToken()}`
      }
    }
  ).then(response => response.json());
/**
 * Get Companies for a client
 * @param {number} clientId
 */
export const getCompaniesForClient = async clientId =>
  fetch(`${baseUrl}/clients/${clientId}/companies`, {
    headers: {
      Authorization: `bearer ${await getToken()}`
    }
  }).then(response => response.json());
/**
 * Get Time slots for a client
 * @param {number} clientId
 */
export const getTimeslotsForClient = async clientId =>
  fetch(`${baseUrl}/clients/${clientId}/timeslots?pageSize=10&pageNumber=0`, {
    headers: {
      Authorization: `bearer ${await getToken()}`
    }
  }).then(response => response.json());

/**
 * Get Corporate Entities for a client
 * @param {number} clientId
 */

export const getEntitiesForClient = async clientId =>
  fetch(`${baseUrl}/clients/${clientId}/entities`, {
    headers: {
      Authorization: `bearer ${await getToken()}`
    }
  }).then(response => response.json());

/**
 * Add a POC to client
 * @param {number} clientId
 * @param {Object} poc
 */
export const addPOCToClient = async (clientId, poc) =>
  await fetch(`${baseUrl}/clients/${clientId}/poc`, {
    method: "POST",
    headers: {
      Authorization: `bearer ${await getToken()}`,
      "Content-Type": "application/json"
    },
    body: JSON.stringify({ poc })
  }).then(response => response.json());
