import React from "react";
import PropTypes from "prop-types";
import Loading from "../../assets/loading.svg";

function SVGLoading(props) {
  const { width, height } = props;
  return (
    <object
      type="image/svg+xml"
      data={Loading}
      width={width || "200px"}
      height={height || "200px"}>
      Loading
    </object>
  );
}

SVGLoading.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string
};

export default SVGLoading;
