import { getToken, baseUrl } from "./index";
import fetch from "isomorphic-fetch";

let abortController = new AbortController();
export const getProjectPhases = async ({
  pageSize,
  pageNumber,
  projectId,
  status,
  phaseId,
  location,
  startDate,
  endDate,
  clientId,
  createdBy
}) => {
  abortController.abort();
  abortController = new AbortController();
  return fetch(
    `${baseUrl}/projectphases?pageSize=${pageSize}&pageNumber=${pageNumber}${
      projectId ? `&projectId=${projectId}` : ``
    }${status ? `&status=${status}` : ``}${
      phaseId ? `&phaseId=${phaseId}` : ``
    }${location ? `&location=${location}` : ``}${
      startDate ? `&startDate=${startDate}` : ``
    }${endDate ? `&endDate=${endDate}` : ``}${
      clientId ? `&clientId=${clientId}` : ``
    }${createdBy ? `&createdBy=${createdBy}` : ``}`,
    {
      headers: {
        Authorization: `bearer ${await getToken()}`
      },
      signal: abortController.signal
    }
  ).then(response => response.json());
};
/**
 * get Project phase by count
 */
export const getProjectPhaseCount = async () =>
  fetch(`${baseUrl}/projectphases/count`, {
    headers: {
      Authorization: `bearer ${await getToken()}`
    }
  }).then(response => response.json());

/**
 * Edit project phase status
 * @param {number} projectPhaseId
 * @param {number} status
 */
export const updateProjectPhaseStatus = async (projectPhaseId, status) =>
  await fetch(`${baseUrl}/projectphases/${projectPhaseId}`, {
    method: "PATCH",
    headers: {
      Authorization: `bearer ${await getToken()}`,
      "Content-Type": "application/json"
    },
    body: JSON.stringify({ status })
  });
/**
 * Delete project phase by Id
 * @param {number} id
 */
export const deleteProjectPhase = async id =>
  await fetch(`${baseUrl}/projectphases/${id}`, {
    method: "DELETE",
    headers: {
      Authorization: `bearer ${await getToken()}`
    }
  });
/**
 * Create a project phase
 * @param {number} projectId
 * @param {number} phaseId
 */
export const createProjectPhase = async ({
  projectId,
  phaseId,
  clientId,
  dataArray,
  phaseCreated
}) =>
  await fetch(`${baseUrl}/projectphases`, {
    method: "POST",
    headers: {
      Authorization: `bearer ${await getToken()}`,
      "Content-Type": "application/json"
    },
    body: JSON.stringify({
      projectId,
      phaseId,
      clientId,
      dataArray,
      phaseCreated
    })
  }).then(response => response.json());

export const getDataPointFromIdAndName = async (projectPhaseId, pdfName) =>
  await fetch(
    `${baseUrl}/projectphases/${projectPhaseId}/dataPointId?pdfName=${pdfName}`,
    {
      headers: {
        Authorization: `bearer ${await getToken()}`
      }
    }
  ).then(response => response.json());

export const updateDataPointByIdAndData = async (
  projectPhaseId,
  dataPointId,
  data
) =>
  await fetch(`${baseUrl}/projectphases/${projectPhaseId}/dataPoint`, {
    method: "PATCH",
    headers: {
      Authorization: `bearer ${await getToken()}`,
      "Content-Type": "application/json"
    },
    body: JSON.stringify({ data, dataPointId })
  }).then(response => response.json());

export const sendProjectPhaseMail = async (projectPhaseId, phaseCreated) =>
  await fetch(`${baseUrl}/projectphases/${projectPhaseId}/mail`, {
    method: "PUT",
    headers: {
      Authorization: `bearer ${await getToken()}`,
      "Content-Type": "application/json"
    },
    body: JSON.stringify({ phaseCreated })
  }).then(response => response.json());
