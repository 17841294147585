import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faList,
  faCogs,
  faPeopleCarry
} from "@fortawesome/free-solid-svg-icons";
const pages = [
  {
    path: "projects",
    icon: <FontAwesomeIcon icon={faList} />,
    name: "Projects",
    sidebar: true,
    groups: [
      "Client Managers",
      "Client Admins",
      "Client Users",
      "Developer Administrators",
      "Application Administrators",
      "Account Managers of Pikkol Organization",
      "Operations User of Pikkol Organization"
    ]
  },
  {
    path: "opscenter",
    icon: <FontAwesomeIcon icon={faPeopleCarry} />,
    name: "Ops Center",
    sidebar: true,
    groups: [
      "Operations User of Pikkol Organization",
      "Developer Administrators",
      "Application Administrators"
    ]
  },
  {
    path: "clientAdmin",
    name: "Administration",
    icon: <FontAwesomeIcon icon={faCogs} />,
    sidebar: true,
    groups: [
      "Client Admins",
      "Developer Administrators",
      "Application Administrators",
      "Account Managers of Pikkol Organization"
    ]
  },
  {
    path: "project",
    name: "Project",
    sidebar: false,
    groups: [
      "Client Managers",
      "Account Managers of Pikkol Organization",
      "Operations User of Pikkol Organization",
      "Client Users",
      "Client Admins",
      "Developer Administrators",
      "Application Administrators"
    ]
  },
  // Keep this at the last always
  {
    path: "",
    name: "Home Page",
    sidebar: false,
    // Add all possible roles in this project here
    groups: [1, 2]
  }
];

export default pages;
