import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import {
  Grid,
  TextField,
  Button,
  IconButton,
  Typography
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import { getComments, addComments, deleteComment } from "../../Api";
import { Skeleton } from "@material-ui/lab";

import { displayTime } from "../../constants";

import * as moment from "moment";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles(theme => ({
  authorClient: {
    color: "#A5a5a5"
  },
  createdTime: {
    fontStyle: "italic",
    color: "#A5a5a5"
  }
}));

function Comments(props) {
  const classes = useStyles();
  const { object, id } = props;
  const accessor = useSelector(state => state.accessor);
  const [loading, setLoading] = useState(false);
  const [newComment, setNewComment] = useState("");
  const [objectList, setList] = useState([]);

  const getCommentsForObject = async () => {
    setLoading(true);
    const { comments } = await getComments(object, id);
    setList(comments);
    setLoading(false);
  };

  useEffect(() => {
    getCommentsForObject();
    const refreshComments = setInterval(() => getCommentsForObject(), 600000);
    return () => {
      clearInterval(refreshComments);
    };
  }, []);

  const handleSubmit = async () => {
    if (newComment.length > 0) {
      await addComments(object, id, newComment);
      setNewComment("");
      getCommentsForObject();
    }
  };

  return (
    <Grid container>
      <Typography variant="h2">Log</Typography>

      {loading ? (
        <Skeleton />
      ) : (
        <Grid
          container
          direction="column"
          alignItems="flex-start"
          style={{ marginTop: "23px" }}
          justify="flex-start">
          {objectList.length === 0 ? (
            <Typography variant="body1">
              No Logs or comments on this {object} yet
            </Typography>
          ) : (
            objectList.map((obj, i) => (
              <Grid
                key={i}
                container
                direction="row"
                style={{ margin: "7px 0px" }}>
                <Grid item xs={2}>
                  <Grid item style={{ fontWeight: "600" }}>
                    <Typography variant="body1">{obj.authorName}</Typography>
                  </Grid>
                  <Grid item>
                    <Typography
                      variant="caption"
                      className={classes.authorClient}>
                      {obj.authorClientName || "Pikkol"}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography
                      variant="caption"
                      className={classes.createdTime}>
                      {displayTime(obj.createdAt)}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item xs={9}>
                  {obj.body.split(/\r|\n/g).map((line, i) => (
                    <Typography variant="body1" key={i}>
                      {line}
                    </Typography>
                  ))}
                </Grid>
                <Grid item xs={1}>
                  {accessor.id === obj.authorId &&
                    moment(obj.createdAt).isAfter(
                      moment().subtract(30, "seconds")
                    ) && (
                      <IconButton
                        size="small"
                        onClick={async () => {
                          await deleteComment(obj.id);
                          getCommentsForObject();
                        }}>
                        <DeleteIcon />
                      </IconButton>
                    )}
                </Grid>
              </Grid>
            ))
          )}
        </Grid>
      )}
      <Grid container direction="row" style={{ marginTop: "14px" }}>
        <TextField
          multiline
          variant="outlined"
          rows={3}
          value={newComment}
          onChange={e => setNewComment(e.target.value)}
          id="new-comment"
          style={{ width: "60%" }}
          label="Add Comment"
        />
        <Button
          onClick={handleSubmit}
          color="primary"
          variant="contained"
          style={{ marginLeft: "14px", height: "40px" }}
          disabled={newComment.length === 0}>
          SUBMIT
        </Button>
      </Grid>
    </Grid>
  );
}

Comments.propTypes = {
  object: PropTypes.string,
  id: PropTypes.number
};

export default Comments;
