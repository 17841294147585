import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { openErrorToast, openSuccessToast } from "../../redux/actions";
import {
  Grid,
  IconButton,
  Button,
  Chip,
  Paper,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Tooltip
} from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";

import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import ImageIcon from "@material-ui/icons/Image";
import ViewColumnIcon from "@material-ui/icons/ViewColumn";
import DeleteIcon from "@material-ui/icons/Delete";
import SendIcon from "@material-ui/icons/Send";

import Select from "react-select";
import { useDropzone } from "react-dropzone";
import {
  uploadFile,
  getFiles,
  getSignedUrl,
  deleteFile,
  convertCSVToJSON,
  addAssetsToTallyo
} from "../../Api";
import { makeStyles } from "@material-ui/styles";
import clsx from "clsx";
import ConfirmPrompt from "../ConfirmPrompt";

const options = [
  { value: "purchaseOrder", label: "Purchase Order" },
  { value: "eWayBill", label: "E-Way Bill" },
  { value: "assetList", label: "Asset List" },
  { value: "proofOfDelivery", label: "Proof Of Delivery" },
  { value: "photo", label: "Photo" },
  { value: "invoice", label: "Tax Invoice" },
  { value: "lorryReceipt", label: "Lorry Receipt" }
];

const useStyles = makeStyles(theme => ({
  dropzoneContainer: {
    flex: 1,
    height: "120px",
    marginTop: "24px",
    backgroundColor: "#FAFAFA",
    padding: "14px",
    color: "#BDBDBD",
    outline: "2px dashed #BDBDBD",
    outlineOffset: "0px",
    outlineWidth: "length 3px",
    "&:hover": {
      outline: `5px dashed ${theme.palette.primary.light}`,
      outlineOffset: "0px",
      outlineWidth: "medium"
    }
  },
  dropZoneDragActive: {
    outline: `5px dashed ${theme.palette.primary.light}`,
    outlineOffset: "2px",
    outlineWidth: "medium"
  },
  fileCard: {
    padding: "14px",
    marginRight: "14px",
    marginBottom: "14px",
    "&:hover": {
      color: theme.palette.primary.main
    }
  },
  fileContainer: { marginTop: "23px" },
  fileCardIcon: {
    color: "#A5a5a5"
  },
  fileDeleteIcon: {
    marginLeft: "24px",
    "&:hover": {
      color: "#FF5252 !important"
    }
  }
}));

export default function Files(props) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const accessor = useSelector(state => state.accessor);

  const [serverCall, setServerCall] = useState(false);

  const [confirmPrompt, setConfirmPrompt] = useState({
    open: false,
    message: "",
    onConfirm: () => {}
  });

  const isAppAdminOrAbove = accessor.groups.some(
    group =>
      ["Developer Administrators", "Application Administrators"].indexOf(
        group
      ) !== -1
  );

  const isPikkolUser =
    isAppAdminOrAbove ||
    accessor.groups.some(
      group =>
        [
          "Operations User of Pikkol Organization",
          "Account Managers of Pikkol Organization"
        ].indexOf(group) !== -1
    );

  const [toUpload, setToUpload] = useState({
    file: false,
    busy: false,
    tag: ""
  });

  const {
    acceptedFiles,
    getRootProps,
    getInputProps,
    isDragActive,
    isFileDialogActive
  } = useDropzone({
    multiple: false,
    maxSize: 1000000,
    noClick: !!toUpload.file
  });

  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [assetListDialog, setAssetListDialog] = useState({
    open: false,
    assets: []
  });

  const customStyles = {
    container: styles => ({
      ...styles,
      width: "320px",
      margin: "5px"
    }),

    menu: styles => ({ ...styles, width: "200px", zIndex: "1000" }),
    menuList: styles => ({ ...styles, width: "200px", zIndex: "1000" }),
    option: (styles, state) => ({
      ...styles,
      background: state.isSelected
        ? "#439fd9"
        : state.isFocused
        ? "#c7e9ff"
        : "#FFFFFF"
    })
  };

  const getFilesForObject = async () => {
    if (props.id) {
      const { files } = await getFiles(props.object, props.id);
      setUploadedFiles(files);
    }
  };

  const handleAssetListUpload = async file => {
    const json = await convertCSVToJSON(file.fileLocation);
    setAssetListDialog({ open: true, assets: json });
  };

  const handleUploadAssetToTallyo = async assets => {
    setServerCall(true);
    const { message } = await addAssetsToTallyo(assets, props.opsRefId);
    setAssetListDialog({ open: false, assets: [] });
    setServerCall(false);
    if (message === "Success") {
      dispatch(openSuccessToast("Assets uploaded successfully"));
      window.location.reload();
    }
  };

  const handleFileDownload = async file => {
    const { signedUrl } = await getSignedUrl(file.fileLocation);
    window.open(signedUrl, "_blank");
  };

  useEffect(() => {
    setToUpload({ ...toUpload, file: acceptedFiles[0] });
  }, [acceptedFiles]);

  useEffect(() => {
    getFilesForObject();
  }, [props.id]);

  return (
    <Grid container direction="column">
      <Typography variant="h2">Files</Typography>
      <Grid
        container
        {...getRootProps({ className: "dropzone" })}
        className={clsx([
          classes.dropzoneContainer,
          ...(isDragActive || isFileDialogActive
            ? [classes.dropZoneDragActive]
            : [])
        ])}
        justify="center">
        <input {...getInputProps()} />
        <Typography variant="body1">
          {toUpload.file
            ? toUpload.tag
              ? `Click upload button, and you're done`
              : `Step 2 of 2: Provide Context`
            : `Step 1 of 2: File Select`}
        </Typography>
        {toUpload.file ? (
          <Grid
            container
            direction="row"
            justify="flex-start"
            style={{ width: "90%" }}
            alignItems="center">
            <Chip
              label={toUpload.file.name}
              onDelete={() => setToUpload({ ...toUpload, file: false })}
            />
            <Select
              options={options}
              placeholder="What am I uploading?"
              styles={customStyles}
              value={toUpload.tag}
              isClearable
              onChange={e => setToUpload({ ...toUpload, tag: e })}
            />
            <Button
              color="primary"
              style={{ width: "160px", margin: "16px" }}
              variant="outlined"
              disabled={!(toUpload.file && toUpload.file.name) || !toUpload.tag}
              onClick={async () => {
                setToUpload({ ...toUpload, busy: true });
                await uploadFile(
                  [toUpload.tag.label],
                  props.id,
                  props.object,
                  toUpload.file
                );
                getFilesForObject();
                setToUpload({
                  ...toUpload,
                  busy: false,
                  file: false,
                  tag: ""
                });
              }}>
              UPLOAD
            </Button>
          </Grid>
        ) : (
          <Grid
            container
            direction="column"
            justify="center"
            alignItems="center"
            spacing={1}>
            <Grid item>
              <Typography variant="body1">
                Drag and drop a file (ONLY ONE)
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="body1">OR</Typography>
            </Grid>
            <Grid item>
              <Typography variant="body1">
                Click to select files the old fashioned way
              </Typography>
            </Grid>
          </Grid>
        )}
      </Grid>
      <Grid container className={classes.fileContainer}>
        {uploadedFiles.length === 0 ? (
          <Typography variant="body1">
            No files uploaded on this {props.object} yet
          </Typography>
        ) : (
          uploadedFiles.map((file, i) => {
            const { name, tags, type } = file.fileContext;
            return (
              <Paper elevation={2} className={classes.fileCard} key={i}>
                <Grid
                  container
                  direction="row"
                  justify="flex-start"
                  alignItems="flex-start">
                  {type === "application/pdf" ? (
                    <PictureAsPdfIcon
                      fontSize="large"
                      className={classes.fileCardIcon}
                    />
                  ) : type === "text/csv" ? (
                    <ViewColumnIcon
                      fontSize="large"
                      className={classes.fileCardIcon}
                    />
                  ) : (
                    <ImageIcon
                      fontSize="large"
                      className={classes.fileCardIcon}
                    />
                  )}
                  <Tooltip title="Download" arrow>
                    <Grid
                      item
                      style={{ marginLeft: "4px", cursor: "pointer" }}
                      onClick={async () => handleFileDownload(file)}>
                      <Typography variant="body2">
                        <b>{tags[0]}</b>
                      </Typography>
                      <Typography variant="body1">{name}</Typography>
                    </Grid>
                  </Tooltip>
                  {(isAppAdminOrAbove || file.uploaderId === accessor.id) && (
                    <Tooltip title="Delete" arrow>
                      <IconButton
                        size="small"
                        className={classes.fileDeleteIcon}
                        onClick={async () => {
                          setConfirmPrompt({
                            open: true,
                            message:
                              "This will delete the file and cannot be retrieved.",
                            onConfirm: async () => {
                              await deleteFile(file.fileId);
                              getFilesForObject();
                            }
                          });
                        }}>
                        <DeleteIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  )}
                  {isPikkolUser &&
                    tags[0] === "Asset List" &&
                    name.indexOf(".csv") !== -1 && (
                      <Tooltip title="Upload on Tallyo">
                        <IconButton
                          size="small"
                          onClick={() => handleAssetListUpload(file)}>
                          <SendIcon fontSize="small" />
                        </IconButton>
                      </Tooltip>
                    )}
                </Grid>
              </Paper>
            );
          })
        )}
        {toUpload.busy && (
          <Paper style={{ width: "100px" }}>
            <Skeleton />
          </Paper>
        )}
      </Grid>
      <AssetListDialog
        open={assetListDialog.open}
        assets={assetListDialog.assets}
        object={props.object}
        id={props.id}
        opsRefId={props.opsRefId}
      />
      <ConfirmPrompt
        onConfirm={confirmPrompt.onConfirm}
        open={confirmPrompt.open}
        message={confirmPrompt.message}
        onClose={() =>
          setConfirmPrompt({ open: false, message: "", onConfirm: () => {} })
        }
      />
    </Grid>
  );

  function AssetListDialog(props) {
    const { assets } = props;
    const [transform, setTransform] = useState({
      scanCode: null,
      groupCode: null
    });

    const options = Object.keys(assets[0] || {}).map(key => ({
      value: key,
      label: key
    }));

    return (
      <Dialog
        open={props.open}
        onClose={() => setAssetListDialog({ open: false, assets: [] })}>
        <DialogTitle>
          Assets for {props.object}-{props.id}
        </DialogTitle>
        <DialogContent>
          # assets : {assets.length} <br />
          Order Id : {props.opsRefId} <br />
          <Select
            placeholder="Select scan code"
            value={transform.scanCode}
            options={options}
            onChange={e => {
              if (!e) setTransform({ ...transform, scanCode: null });
              else {
                if (
                  e.value &&
                  assets.length === new Set(assets.map(a => a[e.value])).size
                )
                  setTransform({ ...transform, scanCode: e || null });
                else {
                  dispatch(
                    openErrorToast(
                      `Values in the column ${e.value} has to be unique`
                    )
                  );
                  setTransform({ ...transform, scanCode: null });
                }
              }
            }}
            isClearable={true}
          />
          <br />
          <Select
            placeholder="Select group code (optional)"
            options={options}
            value={transform.groupCode}
            isClearable={true}
            onChange={e => {
              setTransform({ ...transform, groupCode: e || null });
            }}
          />
          <br />
        </DialogContent>
        <DialogActions>
          <Button
            disabled={!transform.scanCode || !props.opsRefId || serverCall}
            onClick={() => {
              handleUploadAssetToTallyo(
                assets
                  .map(a => ({
                    ...a,
                    scan_code: a[transform.scanCode.value],
                    asset_group_code: transform.groupCode
                      ? a[transform.groupCode.value]
                      : null
                  }))
                  .filter(a => a.scan_code !== "")
              );
            }}>
            Upload
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}
