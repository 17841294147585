import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { closeToast } from "../../redux/actions";

import { Snackbar, IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import CustomSnackbarContentWrapper from "./CustomSnackbarContentWrapper";

export default function Toast(props) {
  const dispatch = useDispatch();
  const toast = useSelector(state => state.toast);

  return (
    <Snackbar
      anchorOrigin={{
        vertical: "top",
        horizontal: "right"
      }}
      autoHideDuration={3000}
      onClose={() => dispatch(closeToast())}
      open={toast.open}
      action={
        <IconButton
          key="undo"
          color="primary"
          size="small"
          onClick={() => dispatch(closeToast())}
        >
          <CloseIcon />
        </IconButton>
      }
    >
      <CustomSnackbarContentWrapper
        variant={toast.variant}
        message={toast.message}
        onClose={() => dispatch(closeToast())}
      />
    </Snackbar>
  );
}
