import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { openErrorToast } from "../../redux/actions";
import { REACT_APP_BASE_URL } from "../../config/constants.json";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Typography,
  Button,
  Grid,
  IconButton,
  Divider
} from "@material-ui/core";
import Select from "react-select";
import CloseIcon from "@material-ui/icons/Close";

import {
  getPhasesForClient,
  getDataFieldsByPhaseId,
  createProjectPhase,
  sendInternalOpsMessage
} from "../../Api";
import RowGrid from "../../components/RowGrid";
import CustomDatePicker from "../../components/CustomDatePicker";
import ClientLocationSelector from "../../components/ClientLocationSelector";
import ClientPOCSelector from "../../components/ClientPOCSelector";
import ClientTimeSlotSelector from "../../components/ClientTimeSlotSelector";

import * as moment from "moment";
import { makeStyles } from "@material-ui/styles";

import ZSchema from "z-schema";

const useStyles = makeStyles(theme => ({
  selectors: {
    margin: "37px 0px"
  },
  dialogContent: {
    minHeight: "500px",
    paddingLeft: "20px"
  }
}));

export default function CreateProjectPhaseDialog(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const accessor = useSelector(state => state.accessor);
  const clientId = useSelector(state => state.clientId);
  // const [selectedPhase, setSelectedPhase] = useState("");
  const [newPhase, setNewPhase] = useState({
    phase: "",
    selectors: [],
    dataArray: [],
    busy: false
  });

  const [clientPhases, setClientPhases] = useState([]);

  const handleAddProjectPhaseButtonClick = async () => {
    const validator = new ZSchema();
    const formatCheck = newPhase.dataArray.map((dataPoint, index) => ({
      isOkay:
        validator.validate(
          dataPoint,
          JSON.parse(newPhase.selectors[index].jsonSchema)
        ) || !newPhase.selectors[index].isMandatory,
      errors: validator.getLastErrors()
    }));
    console.log(formatCheck);

    if (formatCheck.every(arrayCheck => arrayCheck.isOkay)) {
      setNewPhase({ ...newPhase, busy: true });
      const dataArray = newPhase.dataArray
        .filter(object => Object.keys(object).length !== 0)
        .map((object, index) => {
          const { id, ...data } = object;
          return {
            phaseDataFieldId: newPhase.selectors[index].pdfId,
            data
          };
        });
      createProjectPhase({
        projectId: props.projectData.projectId,
        phaseId: newPhase.phase,
        clientId: props.projectData.clientId,
        dataArray,
        phaseCreated
      }).then(({ projectPhase }) => {
        setNewPhase({ ...newPhase, busy: true });
        clear("phaseCreated");
      });
      const createdPhaseName = clientPhases.find(
        clientPhase => clientPhase.id === newPhase.phase
      ).name;
      sendInternalOpsMessage(
        `<${REACT_APP_BASE_URL}/project/${
          props.projectData.projectId
        }|${createdPhaseName} Phase created> for project Ref: ${
          props.projectData.projectRefId
        }. ${phaseCreated ? `ALERT: This is a duplicate phase.` : ``}`
      );
    } else {
      dispatch(
        openErrorToast(
          `Errors in the following data: ${newPhase.selectors
            .filter((selector, index) => !formatCheck[index].isOkay)
            .map((selector, index) => selector.pdfName)
            .join(", ")}`
        )
      );
    }
  };

  const getClientPhases = async () => {
    const searchClientId =
      accessor.clientId || props.projectData.clientId || clientId;
    if (!searchClientId) dispatch(openErrorToast("Choose a client"));
    else {
      const { phases } = await getPhasesForClient(searchClientId);
      setClientPhases(phases);
    }
  };

  useEffect(() => {
    if (newPhase.phase !== "") updateDataFields();
    else clear();
  }, [newPhase.phase]);

  useEffect(() => {
    getClientPhases();
  }, []);

  const updateDataFields = async () => {
    if (newPhase.phase) {
      const { dataFields } = await getDataFieldsByPhaseId(newPhase.phase);
      setNewPhase({
        ...newPhase,
        selectors: dataFields,
        dataArray: dataFields.map(field =>
          field.dfType === "date" ? { date: null } : {}
        )
      });
    }
  };

  const phaseCreated = props.projectPhases.find(
    projectPhase => projectPhase.phaseId === newPhase.phase
  );

  const phaseOptions = clientPhases.map(phase => ({
    value: phase.id,
    label: phase.name
  }));
  const customStyles = {
    container: styles => ({
      ...styles,
      width: "400px"
    }),
    control: styles => ({
      ...styles,
      border: "0px",
      backgroundColor: "#ebe9ee",
      color: "#000000 "
    }),
    menu: styles => ({ ...styles, width: "300px", zIndex: "1000" }),
    menuList: styles => ({ ...styles, width: "300px", zIndex: "1000" }),
    option: (styles, state) => ({
      ...styles,
      background: state.isSelected
        ? "#439fd9"
        : state.isFocused
        ? "#c7e9ff"
        : "#FFFFFF"
    })
  };

  const clear = event => {
    setNewPhase({ phase: "", selectors: [], dataArray: [], busy: false });
    props.onClose(event || "");
  };

  return (
    <Dialog open={props.open} onClose={() => clear()} maxWidth="lg" fullWidth>
      <DialogTitle>
        <Grid container direction="row" justify="space-between">
          <Typography variant="h2">Add a New Phase</Typography>

          <IconButton
            onClick={() => {
              clear("cancelled");
            }}
            size="small">
            <CloseIcon />
          </IconButton>
        </Grid>
      </DialogTitle>
      <Divider />
      <DialogContent className={classes.dialogContent}>
        <RowGrid
          justify="flex-start"
          alignItems="flex-start"
          style={{ margin: "20px 5px" }}>
          <Select
            className="dropdown"
            isClearable={true}
            placeholder="Select a phase"
            styles={customStyles}
            options={phaseOptions}
            onChange={e => {
              setNewPhase({ ...newPhase, phase: e && e.value ? e.value : "" });
            }}
          />

          {phaseCreated && (
            <p style={{ color: "orange", marginLeft: "20px" }}>
              This phase is already created
            </p>
          )}
        </RowGrid>
        <Grid
          container
          direction="column"
          spacing={2}
          className={classes.selectors}>
          {newPhase.selectors.map((field, index) => (
            <Grid item key={index}>
              <Grid
                container
                direction="row"
                justify="flex-start"
                alignItems="flex-start"
                key={index}>
                {field.dfType === "location" && (
                  <ClientLocationSelector
                    value={newPhase.dataArray[index]}
                    label={`${field.pdfName}${
                      field.isMandatory === 1 ? ` *` : ``
                    }`}
                    clientId={props.projectData.clientId}
                    custom={true}
                    onChange={value => {
                      let changedValue = newPhase.dataArray;
                      changedValue[index] = value;
                      setNewPhase({ ...newPhase, dataArray: changedValue });
                    }}
                  />
                )}
                {field.dfType === "point_of_contact" && (
                  <ClientPOCSelector
                    value={newPhase.dataArray[index]}
                    label={`${field.pdfName}${
                      field.isMandatory === 1 ? ` *` : ``
                    }`}
                    clientId={props.projectData.clientId}
                    custom={true}
                    onChange={value => {
                      let changedValue = newPhase.dataArray;
                      changedValue[index] = value;
                      setNewPhase({ ...newPhase, dataArray: changedValue });
                    }}
                  />
                )}
                {field.dfType === "date" && (
                  <CustomDatePicker
                    type="single"
                    className="width200px"
                    placeholder={`${field.pdfName}${
                      field.isMandatory === 1 ? ` *` : ``
                    }`}
                    required={field.isMandatory === 1}
                    date={
                      newPhase.dataArray[index].date &&
                      moment(newPhase.dataArray[index].date)
                    }
                    onChange={({ date }) => {
                      let changedValue = newPhase.dataArray;
                      changedValue[index] = date
                        ? { date: date.format("YYYY-MM-DD") }
                        : { date: null };
                      setNewPhase({ ...newPhase, dataArray: changedValue });
                    }}
                  />
                )}
                {field.dfType === "time_slot" && (
                  <ClientTimeSlotSelector
                    label={`${field.pdfName}${
                      field.isMandatory === 1 ? ` *` : ``
                    }`}
                    clientId={props.projectData.clientId}
                    value={newPhase.dataArray[index]}
                    onChange={value => {
                      let changedValue = newPhase.dataArray;
                      changedValue[index] = value;
                      setNewPhase({ ...newPhase, dataArray: changedValue });
                    }}
                  />
                )}
                {field.dfType === "string" && (
                  <TextField
                    label={`${field.pdfName}${
                      field.isMandatory === 1 ? ` *` : ``
                    }`}
                    className="width200px"
                    onChange={e => {
                      let changedValue = newPhase.dataArray;
                      changedValue[index] = e.target.value
                        ? { string: e.target.value }
                        : "";
                      setNewPhase({ ...newPhase, dataArray: changedValue });
                    }}
                  />
                )}
                {field.dfType === "number" && (
                  <TextField
                    label={`${field.pdfName}${
                      field.isMandatory === 1 ? ` *` : ``
                    }`}
                    type="number"
                    className="width200px"
                    onChange={e => {
                      let changedValue = newPhase.dataArray;
                      changedValue[index] = e.target.value
                        ? { number: e.target.value }
                        : "";
                      setNewPhase({ ...newPhase, dataArray: changedValue });
                    }}
                  />
                )}
              </Grid>
            </Grid>
          ))}
        </Grid>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button
          disabled={newPhase.busy}
          variant="contained"
          color="primary"
          style={{ marginRight: "30px", marginBottom: "30px" }}
          onClick={handleAddProjectPhaseButtonClick}>
          Add Project Phase
        </Button>
      </DialogActions>
    </Dialog>
  );
}
