import React from "react";

import { Grid } from "@material-ui/core";
import { number, object } from "prop-types";

export default function RowGrid(props) {
  return (
    <Grid
      container
      spacing={props.spacing || 3}
      style={props.style || { margin: "0px" }}
      direction="row"
      alignItems={props.alignItems || "center"}
      justify={props.justify || "space-evenly"}
    >
      {props.children}
    </Grid>
  );
}

RowGrid.propTypes = {
  spacing: number,
  style: object
};
