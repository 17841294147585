import React from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Dialog,
  DialogTitle,
  Select,
  Grid,
  DialogContent,
  DialogActions,
  Divider,
  Button,
  IconButton,
  MenuItem,
  FormControl,
  InputLabel,
  Typography,
  useTheme
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import DeleteIcon from "@material-ui/icons/Delete";
import Files from "../../components/Files";
import Comments from "../../components/Comments";
import { updateProjectPhaseStatus, deleteProjectPhase } from "../../Api";
import { openSuccessToast } from "../../redux/actions";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles(theme => ({
  phaseDataRow: {}
}));

export default function StatusEditDialog(props) {
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();
  const accessor = useSelector(state => state.accessor);
  const open = props.open;
  const projectPhase = props.projectPhase;

  const isOpsUser = accessor.groups.some(group =>
    [
      "Developer Administrators",
      "Application Administrators",
      "Operations User of Pikkol Organization"
    ].includes(group)
  );

  return (
    <Dialog open={open} onClose={() => props.onClose()} maxWidth="lg" fullWidth>
      <DialogTitle>
        <Grid container direction="row" justify="space-between">
          <Typography variant="h2">{projectPhase.phaseName || ""}</Typography>

          <IconButton onClick={() => props.onClose()}>
            <CloseIcon />
          </IconButton>
        </Grid>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Grid container direction="column" style={{ margin: "10px 0px" }}>
          {projectPhase.phaseData &&
            projectPhase.phaseData.map((point, index) => {
              const [[key, values]] = Object.entries(point);
              return (
                <Grid key={index} className={classes.phaseDataRow}>
                  <Typography variant="body2">
                    {key}
                    <b>
                      {Object.entries(values).map(([key2, value2]) => {
                        return (
                          (key2 === "name" ||
                            key2 === "date" ||
                            key2 === "email" ||
                            key2 === "string" ||
                            key2 === "number" ||
                            key2 === "buildingName" ||
                            key2 === "floorNumber" ||
                            key2 === "liftAvailability" ||
                            key2 === "phoneNumber") &&
                          ` : ${
                            key2 === "floorNumber"
                              ? "Floor: "
                              : key2 === "liftAvailability"
                              ? `Lift Available`
                              : ``
                          } ${
                            typeof value2 === "boolean"
                              ? value2
                                ? `yes`
                                : `NO`
                              : value2
                          } `
                        );
                      })}
                    </b>
                  </Typography>
                </Grid>
              );
            })}
        </Grid>
        <Grid container style={{ margin: "23px 0px" }}>
          <Files object="project_phase" id={projectPhase.id} />
        </Grid>
        <Grid container style={{ margin: "23px 0px" }}>
          <Comments object="project_phase" id={projectPhase.id} />
        </Grid>
      </DialogContent>
      <Divider />
      <DialogActions style={{ marginBottom: "30px", marginRight: "30px" }}>
        {projectPhase.status === 1 && (
          <Button
            style={{
              color: theme.palette.error.main
            }}
            onClick={() => {
              deleteProjectPhase(projectPhase.id).then(() => props.onClose());
              // Todo Confirmation
            }}>
            DELETE PHASE
            <DeleteIcon />
          </Button>
        )}
        <FormControl>
          <InputLabel>Status</InputLabel>
          <Select
            value={projectPhase.status || ""}
            color="primary"
            disabled={!isOpsUser}
            onChange={async e => {
              await updateProjectPhaseStatus(projectPhase.id, e.target.value);
              dispatch(openSuccessToast("Status updated"));
              props.onClose("update");
            }}>
            <MenuItem value={1}>Requested</MenuItem>
            <MenuItem value={2}>Accepted</MenuItem>
            <MenuItem value={8}>Completed</MenuItem>
          </Select>
        </FormControl>
      </DialogActions>
    </Dialog>
  );
}
