import React from "react";
import PropTypes from "prop-types";
import { IconButton, SnackbarContent } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import clsx from "clsx";

import { makeStyles } from "@material-ui/core/styles";

const useStyles1 = makeStyles(theme => ({
  success: {
    backgroundColor: "#43D95E",
    color: "#000000"
  },
  error: {
    backgroundColor: "#FF5252"
  },
  info: {
    backgroundColor: "#FFF678",
    color: "#000000"
  },
  warning: {
    backgroundColor: "#FF9D4F",
    color: "#000000"
  },
  icon: {
    fontSize: 20
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1)
  }
}));

function CustomSnackbarContentWrapper(props) {
  const classes = useStyles1();
  const { className, message, onClose, variant, ...others } = props;
  return (
    <SnackbarContent
      className={clsx(classes[variant], className)}
      message={<span id="client-snackbar">{message}</span>}
      action={[
        <IconButton
          key="1"
          onClick={() => props.onClose()}
          className={classes[variant]}>
          <CloseIcon />
        </IconButton>
      ]}
      {...others}
    />
  );
}

CustomSnackbarContentWrapper.propTypes = {
  variant: PropTypes.oneOf(["success", "error", "warning", "info"]),
  message: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired
};

export default CustomSnackbarContentWrapper;
