import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { getProjectByRefId, createProject, getProjectsUDF } from "../../Api";
import ZSchema from "z-schema";

import { Button, Grid, TextField, Chip } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import AddIcon from "@material-ui/icons/Add";

import Header from "../../components/Header";
import ClientSelector from "../../components/ClientSelector";
import { openErrorToast } from "../../redux/actions";
import Joyride, { STATUS } from "react-joyride";
import { joyrideStyles } from "../../constants";

export default function NewProjectPage(props) {
  const validator = new ZSchema({ noEmptyStrings: true });
  const accessor = useSelector(state => state.accessor);
  const dispatch = useDispatch();
  const reduxClientId = useSelector(state => state.clientId);
  const [serverCall, setServerCall] = useState(false);

  const [projectCheckDone, setProjectCheckDone] = useState(false);
  const [projectRefId, setProjectRefId] = useState("");
  const [projectsWithRefId, setProjects] = useState([]);
  const [tagData, setTagData] = useState("");
  const [tags, setTags] = useState([]);
  const [UDF, setUDF] = useState({ fields: [], schema: {} });
  const [udfData, setUDFData] = useState({});
  const clientId = accessor.clientId || reduxClientId;
  const handleKeyPress = e => {
    if (e.which === 13) {
      if (e.target.id === "tag" && tagData !== "") {
        setTags([...tags, tagData]);
        setTagData("");
      } else if (e.target.id === "refId" && projectRefId !== "")
        handleGetProjectsWithRefId();
    }
  };
  const handleGetProjectsWithRefId = async () => {
    if (clientId) {
      setServerCall(true);
      const { projects } = await getProjectByRefId(clientId, projectRefId);
      setProjectCheckDone(true);
      setServerCall(false);
      setProjects(projects);
    } else {
      dispatch(openErrorToast("Client id is not set."));
    }
  };

  const handleAddProject = async () => {
    const validSchema = validator.validate(udfData, UDF.schema);
    if (!validSchema) {
      const errors = validator.getLastErrors();
      dispatch(openErrorToast(errors.map(error => error.message).join(",")));
    } else {
      setServerCall(true);
      if (clientId) {
        createProject({ clientId, projectRefId, tags, udfData }).then(
          response => {
            setServerCall(false);
            props.history.push(`/project/${response.project[0]}`);
          }
        );
      } else {
        setServerCall(false);
        dispatch(openErrorToast("Client id is not set."));
      }
    }
  };

  const updateUDFs = async () => {
    const { schema } = await getProjectsUDF(clientId);
    const jsonSchema = JSON.parse(schema);
    const { ui_fields, properties, required } = jsonSchema;

    setUDF({
      fields: ui_fields.map(field => ({
        [field]: properties[field],
        required: required.some(rq => rq === field)
      })),
      schema: jsonSchema
    });
    setUDFData(
      ui_fields.reduce((acc, field) => {
        return Object.assign(acc, {
          [field]: ""
        });
      }, {})
    );
  };

  useEffect(() => {
    clientId && updateUDFs();
  }, [clientId]);

  useEffect(() => {
    setProjectCheckDone(false);
    setProjects([]);
    setTags([]);
  }, [projectRefId]);

  const steps = [
    {
      title: "Project reference id",
      target: ".project-ref-id",
      disableBeacon: true,
      content:
        "Enter the project ref id here. This step prevents creation of duplicate project entries"
    }
  ];

  const newProjectTutorial = window.localStorage.getItem(
    "new-project-tutorial"
  );

  return (
    <Grid className="mainContainer">
      <Header title="New Project" />
      {!newProjectTutorial && (
        <Joyride
          steps={steps}
          continuous={true}
          scrollToFirstStep={true}
          showProgress={true}
          styles={{ options: joyrideStyles }}
          callback={({ status }) => {
            if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
              window.localStorage.setItem("new-project-tutorial", true);
            }
          }}
        />
      )}
      <Grid container direction="column" justify="center" alignItems="center">
        <Grid container direction="row" justify="center" alignItems="flex-end">
          <ClientSelector />
          <TextField
            value={projectRefId}
            variant="outlined"
            className="project-ref-id"
            id="refId"
            label="Enter project id"
            style={{ margin: "10px", height: "40px", width: "400px" }}
            onChange={e => setProjectRefId(e.target.value.replace(/ /, ""))}
          />
          <Button
            variant="contained"
            style={{ margin: "10px" }}
            onClick={handleGetProjectsWithRefId}>
            PROCEED
          </Button>
        </Grid>
        {serverCall && <Skeleton />}
        {projectCheckDone &&
          (projectsWithRefId.length > 0 ? (
            <Grid
              container
              direction="column"
              justify="center"
              alignItems="center"
              spacing={5}
              style={{ color: "orange", margin: "10px" }}>
              <p>A project with this id exists already.</p>
              {projectsWithRefId.map(project => (
                <Button
                  key={project.id}
                  onClick={() => props.history.push(`/project/${project.id}`)}>
                  Go to Project
                </Button>
              ))}
            </Grid>
          ) : (
            <Grid
              container
              direction="column"
              justify="center"
              alignItems="center"
              style={{ margin: "40px" }}>
              {UDF &&
                UDF.fields &&
                UDF.fields.map((field, index) => (
                  <Grid item key={Object.keys(field)[0]}>
                    {
                      <TextField
                        required={field.required}
                        type={
                          Object.values(field)[0].type === "number"
                            ? "number"
                            : "string"
                        }
                        label={Object.keys(field)[0]}
                        onChange={e => {
                          let newUDFData = udfData;
                          newUDFData[Object.keys(field)[0]] =
                            Object.values(field)[0].type === "number"
                              ? +e.target.value
                              : e.target.value;
                          setUDFData(newUDFData);
                        }}
                      />
                    }
                  </Grid>
                ))}
              <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
                style={{ margin: "10px" }}>
                <TextField
                  value={tagData}
                  id="tag"
                  className="tag-entry"
                  onChange={e => setTagData(e.target.value)}
                  label="Enter tags(if any)"
                  onKeyPress={handleKeyPress}
                />
                {tags.map((tag, index) => (
                  <Chip
                    key={tag}
                    label={tag}
                    style={{ margin: "3px" }}
                    onDelete={() => setTags(tags.filter((t, i) => i !== index))}
                  />
                ))}
                <Button
                  onClick={() => {
                    if (tagData !== "") {
                      setTags([...tags, tagData]);
                      setTagData("");
                    }
                  }}>
                  <AddIcon />
                </Button>
              </Grid>

              <Button
                id="createNew"
                color="primary"
                variant="contained"
                disabled={serverCall}
                style={{ marginTop: "30px" }}
                onClick={handleAddProject}>
                CREATE PROJECT
              </Button>
            </Grid>
          ))}
      </Grid>
    </Grid>
  );
}
