import { UPDATE_TOAST, UPDATE_ACCESSOR, UPDATE_CLIENT } from "../../constants";

const initialState = {
  toast: { open: false, message: "", variant: "success" },
  accessor: {},
  clientId: ""
};

export default function rootReducer(state = initialState, action) {
  switch (action.type) {
    case UPDATE_TOAST:
      return { ...state, toast: action.payload.toast };
    case UPDATE_ACCESSOR:
      return { ...state, accessor: action.payload.accessor };
    case UPDATE_CLIENT:
      return { ...state, clientId: action.payload.clientId };
    default:
      return state;
  }
}
