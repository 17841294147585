import { getToken, baseUrl } from "./index";
import fetch from "isomorphic-fetch";

/**
 * Get Accounts for a client
 * @param {number} clientId
 */
export const getAccountsForClientId = async (
  clientId,
  pageSize,
  pageNumber,
  search
) =>
  fetch(
    `${baseUrl}/accounts?clientId=${clientId}&pageSize=${pageSize}&pageNumber=${pageNumber}${
      search.length > 2 ? `&search=${search}` : ``
    }`,
    {
      headers: {
        Authorization: `bearer ${await getToken()}`
      }
    }
  ).then(response => response.json());

/**
 * Get account details for an account with the given email
 * @param {string} email
 */

export const getAccount = async email =>
  fetch(`${baseUrl}/accounts?email=${email}`, {
    headers: {
      Authorization: `bearer ${await getToken()}`
    }
  }).then(response => response.json());

/**
 * Add a new "accounts" record with this client Id (can be null also for Pikkol users)
 * @param {number} clientId
 * @param {object} accountData
 */

export const addAccountToClient = async (clientId, accountData) =>
  await fetch(`${baseUrl}/accounts`, {
    method: "POST",
    headers: {
      Authorization: `bearer ${await getToken()}`,
      "Content-Type": "application/json"
    },
    body: JSON.stringify({ ...accountData, clientId })
  }).then(response => response.json());

/**
 * Add a particular role/group to an account
 * @param {number} accountId
 * @param {number} groupId
 */
export const addGroupToAccount = async (accountId, groupId) =>
  fetch(`${baseUrl}/accounts/${accountId}`, {
    method: "PATCH",
    headers: {
      Authorization: `bearer ${await getToken()}`,
      "Content-Type": "application/json"
    },
    body: JSON.stringify({ groupId, operation: "add" })
  }).then(response => response.json());

/**
 * Update when the account accessed ULI the latest
 * @param {number} accountId
 */
export const setLatestAccessTime = async accountId =>
  fetch(`${baseUrl}/accounts/${accountId}`, {
    method: "PATCH",
    headers: {
      Authorization: `bearer ${await getToken()}`,
      "Content-Type": "application/json"
    },
    body: JSON.stringify({ operation: "setAccessTime" })
  }).then(response => response.json());
/**
 * Remove Group from account id
 * @param {number} accountId
 * @param {number} groupId
 */

export const removeGroupFromAccount = async (accountId, groupId) =>
  fetch(`${baseUrl}/accounts/${accountId}`, {
    method: "PATCH",
    headers: {
      Authorization: `bearer ${await getToken()}`,
      "Content-Type": "application/json"
    },
    body: JSON.stringify({ groupId, operation: "remove" })
  }).then(response => response.json());

/**
 * Activate account
 * @param {number} accountId
 */

export const activateAccount = async accountId =>
  fetch(`${baseUrl}/accounts/${accountId}`, {
    method: "PATCH",
    headers: {
      Authorization: `bearer ${await getToken()}`,
      "Content-Type": "application/json"
    },
    body: JSON.stringify({ operation: "activate" })
  });

/**
 * Deactivate account
 * @param {number} accountId
 */

export const deactivateAccount = async accountId =>
  fetch(`${baseUrl}/accounts/${accountId}`, {
    method: "PATCH",
    headers: {
      Authorization: `bearer ${await getToken()}`,
      "Content-Type": "application/json"
    },
    body: JSON.stringify({ operation: "deactivate" })
  });

/**
 * Set verified flag of given account as true
 * @param {number} accountId
 */
export const verifyAccount = async accountId =>
  fetch(`${baseUrl}/accounts/${accountId}`, {
    method: "PATCH",
    headers: {
      Authorization: `bearer ${await getToken()}`,
      "Content-Type": "application/json"
    },
    body: JSON.stringify({ operation: "verify" })
  });
