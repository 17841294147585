import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { openErrorToast } from "../../redux/actions";

import { Grid, TextField } from "@material-ui/core";
import { getPOCsForClient } from "../../Api";
import Select from "react-select";

import { emailRegex, phoneRegex } from "../../constants";

export default function ClientPOCSelector(props) {
  const dispatch = useDispatch();
  const accessor = useSelector(state => state.accessor);
  const clientId = useSelector(state => state.clientId);

  const [customPOC, setCustomPOC] = useState({
    name: (props.value && !props.value.id && props.value.name) || "",
    email: (props.value && !props.value.id && props.value.email) || "",
    phoneNumber:
      (props.value && !props.value.id && props.value.phoneNumber) || ""
  });

  const [clientPOCs, setClientPOCs] = useState([]);
  const [showCustom, setShowCustom] = useState(false);
  const getThisClientPOCs = async () => {
    const searchClientId = accessor.clientId || props.clientId || clientId;
    if (!searchClientId) dispatch(openErrorToast("Choose a client"));
    else {
      const { pocs } = await getPOCsForClient(searchClientId);
      setClientPOCs(pocs);
    }
  };

  useEffect(() => {
    if (customPOC.name !== "") props.onChange(customPOC);
  }, [customPOC]);

  useEffect(() => {
    getThisClientPOCs();
  }, []);

  const pocOptions = [
    ...(props.custom ? [{ value: 0, label: "Custom" }] : []),
    ...clientPOCs.map(poc => ({
      value: poc.name,
      label: poc.name
    }))
  ];

  const customStyles = {
    container: styles => ({ ...styles, height: "40px" }),
    control: styles => ({ ...styles, height: "40px", borderColor: "#dbdbdb" }),
    menu: styles => ({ ...styles, width: "300px", zIndex: "1000" }),
    menuList: styles => ({ ...styles, width: "300px", zIndex: "1000" }),
    option: (styles, state) => ({
      ...styles,
      background: state.isSelected
        ? "#439fd9"
        : state.isFocused
        ? "#c7e9ff"
        : "#FFFFFF"
    })
  };
  return (
    <Grid
      container
      direction="row"
      justify="flex-start"
      alignItems="flex-start">
      <Select
        placeholder={props.label}
        options={pocOptions}
        isClearable={true}
        styles={customStyles}
        className="dropdown"
        onChange={e => {
          if (e && e.value === 0) setShowCustom(true);
          else {
            setShowCustom(false);
            props.onChange(
              e && e.value ? clientPOCs.find(poc => poc.name === e.value) : ""
            );
          }
        }}
      />
      {showCustom && (
        <TextField
          value={customPOC.name}
          style={{ marginLeft: "10px" }}
          error={customPOC.name.length === 0}
          label="POC Name"
          onChange={e => setCustomPOC({ ...customPOC, name: e.target.value })}
        />
      )}
      {showCustom && (
        <TextField
          value={customPOC.email}
          style={{ marginLeft: "10px" }}
          label="POC Email"
          error={!emailRegex.test(customPOC.email)}
          onChange={e => setCustomPOC({ ...customPOC, email: e.target.value })}
        />
      )}
      {showCustom && (
        <TextField
          value={customPOC.phoneNumber}
          style={{ marginLeft: "10px" }}
          label="POC Phone"
          error={!phoneRegex.test(customPOC.phoneNumber)}
          onChange={e =>
            setCustomPOC({ ...customPOC, phoneNumber: e.target.value })
          }
        />
      )}
    </Grid>
  );
}
