import { auth } from "firebase/app";
import fetch from "isomorphic-fetch";

import {
  REACT_APP_BASE_URL,
  TALLYO_APP_BASE_URL,
  TECH_ALERT_WEB_HOOK,
  INTERNAL_CHAT_WEB_HOOK
} from "../config/constants.json";

export const baseUrl = `${REACT_APP_BASE_URL}/api`;

export const tallyoBaseUrl = `${TALLYO_APP_BASE_URL}/api`;

export const getToken = async () => await auth().currentUser.getIdToken();

export * from "./accounts";
export * from "./projectphases";
export * from "./projects";
export * from "./clients";
export * from "./comments";
export * from "./tallyo";
export * from "./files";

/**
 * Get States
 */
export const getStates = async () =>
  fetch(`${baseUrl}/states`, {
    headers: {
      Authorization: `bearer ${await getToken()}`
    }
  }).then(response => response.json());

/**
 * Get the individual data fields by phase Id - for populating UI
 * @param {number} phaseId
 */
export const getDataFieldsByPhaseId = async phaseId =>
  await fetch(`${baseUrl}/phases/${phaseId}/datafields`, {
    headers: {
      Authorization: `bearer ${await getToken()}`
    }
  }).then(response => response.json());

/**
 * Send message to internal group
 * @param {string} text
 */
export const sendInternalOpsMessage = async text =>
  fetch(INTERNAL_CHAT_WEB_HOOK, {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify({ text })
  }).then(response => response.json());
export const sendTechAlert = async text =>
  fetch(TECH_ALERT_WEB_HOOK, {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify({ text })
  }).then(response => response.json());
/**
 *
 * @param {object} param0
 */
export const reverseGeocode = async ({ lat, lon }) =>
  fetch(
    `https://nominatim.openstreetmap.org/reverse?lat=${lat}&lon=${lon}&format=json`
  ).then(response => response.json());
