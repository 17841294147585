import {
  UPDATE_TOAST,
  UPDATE_CONTEXT,
  UPDATE_ACCESSOR,
  UPDATE_CLIENT
} from "../constants";
/**
 * Opens a toast
 * @param {string} message
 */
export function openSuccessToast(message) {
  return {
    type: UPDATE_TOAST,
    payload: { toast: { variant: "success", open: true, message } }
  };
}
/**
 * Opens a toast
 * @param {string} message
 */
export function openErrorToast(message) {
  return {
    type: UPDATE_TOAST,
    payload: { toast: { variant: "error", open: true, message } }
  };
}
/**
 * Opens a toast
 * @param {string} message
 */
export function openWarningToast(message) {
  return {
    type: UPDATE_TOAST,
    payload: { toast: { variant: "warning", open: true, message } }
  };
}

export function closeToast() {
  return {
    type: UPDATE_TOAST,
    payload: { toast: { open: false, message: "" } }
  };
}

/**
 * @typedef {{client}} ClientContext
 * @param {ClientContext} context
 */
export function updateContext(context) {
  return {
    type: UPDATE_CONTEXT,
    payload: { context }
  };
}

/**
 *
 * @param {Object} account
 */
export function updateAccessor(accessor) {
  return {
    type: UPDATE_ACCESSOR,
    payload: { accessor }
  };
}

/**
 * @param {number} clientId
 */
export function updateClient(clientId) {
  return {
    type: UPDATE_CLIENT,
    payload: { clientId }
  };
}
