import React from "react";

import { Grid, Button } from "@material-ui/core";
import moment from "moment";

const customRanges = [
  {
    label: "Today",
    startDate: moment(),
    endDate: moment()
  },
  {
    label: "Yesterday",
    startDate: moment().subtract(1, "day"),
    endDate: moment().subtract(1, "day")
  },
  {
    label: "Tomorrow",
    startDate: moment().add(1, "day"),
    endDate: moment().add(1, "day")
  },
  {
    label: "Week so far",
    startDate: moment().startOf("week"),
    endDate: moment()
  },
  {
    label: "Last week",
    startDate: moment()
      .subtract(1, "week")
      .startOf("week"),
    endDate: moment()
      .subtract(1, "week")
      .endOf("week")
  },
  {
    label: "Month so far",
    startDate: moment().startOf("month"),
    endDate: moment()
  },
  {
    label: "Last month",
    startDate: moment()
      .subtract(1, "month")
      .startOf("month"),
    endDate: moment()
      .subtract(1, "month")
      .endOf("month")
  }
];

function DateRangeButtons(props) {
  return (
    <Grid container direction="column">
      {customRanges.map(range => (
        <Button
          onClick={() =>
            props.onChange({
              startDate: range.startDate,
              endDate: range.endDate
            })
          }
          key={range.label}>
          {range.label}
        </Button>
      ))}
    </Grid>
  );
}

DateRangeButtons.propTypes = {};

export default DateRangeButtons;
