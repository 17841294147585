import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { openErrorToast } from "../../redux/actions";

import { getEntitiesForClient } from "../../Api";
import { Grid } from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import { tableOptions, entityColumns } from "../../constants";

import RowGrid from "../../components/RowGrid";

export default function ClientEntityHandler(props) {
  const dispatch = useDispatch();
  const accessor = useSelector(state => state.accessor);
  const clientId = useSelector(state => state.clientId);

  const [clientEntities, setClientEntites] = useState([]);
  const getClientEntities = async () => {
    const searchClientId = accessor.clientId || clientId;
    if (!clientId) dispatch(openErrorToast("Choose a client"));
    else {
      const { entities } = await getEntitiesForClient(searchClientId);
      setClientEntites(entities);
    }
  };
  useEffect(() => {
    getClientEntities();
  }, [clientId]);

  return (
    <Grid>
      <RowGrid></RowGrid>

      <MUIDataTable
        data={clientEntities}
        columns={entityColumns}
        options={tableOptions}
      />
    </Grid>
  );
}
