import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { openErrorToast } from "../../redux/actions";

import { getTimeslotsForClient } from "../../Api";
import {
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField
} from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import { timeslotColumns, tableOptions } from "../../constants";

export default function ClientTimeSlotHandler(props) {
  const dispatch = useDispatch();
  const [dialog, setDialog] = useState({ open: false });
  const accessor = useSelector(state => state.accessor);
  const clientId = useSelector(state => state.clientId);
  const [newTimeslot, setNewTimeslot] = useState({
    name: "",
    startTime: "00:00",
    endTime: "23:59",
    nameError: false,
    startTimeError: false,
    endTimeError: false
  });
  const [clientTimeslots, setClientTimeslots] = useState([]);
  const getClientTimeslots = async () => {
    const searchClientId = accessor.clientId || clientId;
    if (!clientId) dispatch(openErrorToast("Choose a client"));
    else {
      const { timeSlots } = await getTimeslotsForClient(searchClientId);
      setClientTimeslots(timeSlots);
    }
  };
  useEffect(() => {
    getClientTimeslots();
  }, [clientId]);
  return (
    <Grid>
      <MUIDataTable
        data={clientTimeslots}
        options={tableOptions}
        columns={timeslotColumns}
      />
      <Dialog open={dialog.open} onClose={() => setDialog({ open: false })}>
        <DialogTitle>Add Timeslot</DialogTitle>
        <DialogContent>
          <Grid direction="column" container>
            <TextField
              value={newTimeslot.name}
              onChange={e =>
                setNewTimeslot({ ...newTimeslot, name: e.target.value })
              }
              label="Enter slot Name"
            />
            <TextField
              type="time"
              value={newTimeslot.startTime}
              onChange={e => {
                setNewTimeslot({ ...newTimeslot, startTime: e.target.value });
              }}
              inputProps={{
                step: 3600 // 1 hour
              }}
            />
          </Grid>
        </DialogContent>
      </Dialog>
    </Grid>
  );
}
