import { getToken, baseUrl } from "./index";
import fetch from "isomorphic-fetch";

/**
 * Upload a File with tags, association type, associationId,
 * @param {string[]} tags
 * @param {number} id
 * @param {string} type
 * @param {File} file
 */
export const uploadFile = async (tags, id, type, file) => {
  let body = new FormData();
  body.append("context", JSON.stringify({ tags }));
  body.append("object", JSON.stringify({ id, type }));
  body.append("file", file, file.name);
  await fetch(`${baseUrl}/files/`, {
    method: "POST",
    headers: {
      Authorization: `bearer ${await getToken()}`
    },
    body
  }).then(response => response.json());
};

/**
 * Get a file for an object , characterised by (type=association_type,id=association_id)
 * @param {string} type
 * @param {number} id
 */
export const getFiles = async (type, id) =>
  await fetch(`${baseUrl}/files/${type}/${id}`, {
    headers: {
      Authorization: `bearer ${await getToken()}`
    }
  }).then(response => response.json());

/**
 * Get the signed URL to download/view file
 * @param {string} key
 */
export const getSignedUrl = async key =>
  await fetch(`${baseUrl}/files?key=${key}`, {
    headers: {
      Authorization: `bearer ${await getToken()}`
    }
  }).then(response => response.json());

/**
 * Delete file, given the id
 * @param {number} id
 */
export const deleteFile = async id =>
  await fetch(`${baseUrl}/files/${id}`, {
    method: "DELETE",
    headers: {
      Authorization: `bearer ${await getToken()}`,
      "Content-Type": "application/json"
    }
  }).then(response => response.json());

/**
 * Converts a csv file uploaded on s3, to an array of json objects
 * @param {string} key
 */
export const convertCSVToJSON = async key =>
  await fetch(`${baseUrl}/files/read_csv?key=${key}`, {
    headers: {
      Authorization: `bearer ${await getToken()}`
    }
  }).then(response => response.json());
