import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { openSuccessToast, openErrorToast } from "../../redux/actions";

import {
  Grid,
  Chip,
  Button,
  IconButton,
  TextField,
  Box,
  Paper,
  Typography
} from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import LinkIcon from "@material-ui/icons/Link";

import Header from "../../components/Header";
import Files from "../../components/Files";

import { getPhases, getProject, updateProject } from "../../Api";
import CreateProjectPhaseDialog from "./CreateProjectPhaseDialog";
import CreateOrderDialog from "./CreateOrderDialog";
import StatusEditDialog from "./StatusEditDialog";
import * as moment from "moment";
import Comments from "../../components/Comments";
import { displayTime } from "../../constants";
import { makeStyles } from "@material-ui/styles";
import PackingData from "../../components/PackingData";
import TallyData from "../../components/TallyData";

// import OpsData from "./OpsData";
// const OpsData = lazy(() => import("./OpsData"));

const statuses = {
  1: "requested",
  2: "accepted",
  4: "started",
  8: "completed"
};
const statusColors = { 1: "#FF9800", 2: "#FFCF00", 4: "#439fd9", 8: "#4CAF50" };

const useStyles = makeStyles(theme => ({
  addButton: {
    borderRadius: "999px"
  },
  marginTop10: {
    marginTop: "10px"
  },
  phaseCard: {
    width: 320,
    margin: "23px 10px 0px 0px",
    fontSize: "0.9em",
    padding: "23px",
    cursor: "pointer",
    "&:hover": {
      color: theme.palette.primary.main
    }
  },
  section: {
    padding: "37px",
    border: "1px solid #e3e3e3",
    borderRadius: "4px",
    marginBottom: "30px"
  },
  projectLabel: {
    width: "200px"
  },
  projectTitle: {
    marginBottom: "8px"
  },
  statusBox: {
    padding: "3px 14px",
    color: "#000000",
    width: "fit-content"
  },
  entries: {},
  createdTime: {
    fontStyle: "italic",
    color: "#A5a5a5"
  },
  phaseName: {
    margin: "3px 0px"
  }
}));

export default function ProjectPage(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const accessor = useSelector(state => state.accessor);
  const projectId = props.match.params.id;

  const [dialogs, setDialogs] = useState({
    addProject: false,
    createOrder: false,
    updateProjectPhase: {
      open: false,
      projectPhase: {}
    }
  });
  const [projectData, setProjectData] = useState({});
  const [projectPhases, setProjectPhases] = useState([]);
  const [loading, setLoading] = useState({
    projectData: false,
    phases: false
  });
  const [newTag, setNewTag] = useState({ add: false, value: "" });
  const updateOpsRefId = refId =>
    updateProject(projectId, { opRefId: refId || projectData.operationalRefId })
      .then(() => getProjectData())
      .then(() => dispatch(openSuccessToast("Op ref Id updated")));

  const getProjectPhasesForProject = async () => {
    setLoading({ ...loading, phases: true });
    const { phases, error } = await getPhases(projectId);
    if (!error) {
      setLoading({ ...loading, phases: false });
      setProjectPhases(phases.reverse());
    }
  };

  const handleAddTag = () => {
    updateProject(projectId, {
      tags: [...projectData.tags, newTag.value]
    })
      .then(() => setNewTag({ value: "", add: false }))
      .then(() => {
        dispatch(openSuccessToast("New tag added"));
        getProjectData();
      });
  };

  const handleKeyPress = e => {
    if (e.target.id === "new-tag" && e.which === 13 && newTag.value.length > 0)
      handleAddTag();
    else if (e.target.id === "operational-ref-id" && e.which === 13)
      updateOpsRefId();
  };

  const getProjectData = async () => {
    setLoading({ ...loading, projectData: true });
    const { project, error } = await getProject(projectId);
    if (
      error ||
      (!project && accessor.clientId && accessor.clientId !== project.clientId)
    ) {
      dispatch(openErrorToast("You don't have access to that project yet."));
      props.history.push("/projects");
    } else {
      setLoading({ ...loading, projectData: false });
      setProjectData(project);
    }
  };

  const displayProjectPhases = projectPhases.map(phase => ({
    ...phase,
    data: Object.entries(phase.phaseData).map(([key, values]) => ({
      [key]: values.date
        ? moment(values.date).format("DD-MMM-YYYY")
        : values.nickname || values.string || values.number || values.name
    }))
  }));

  const projectDataFieldNames = projectPhases.reduce(
      (accum, phase) => [...accum, ...phase.dataFieldTypes],
      []
    ),
    projectDataArray = projectPhases.reduce(
      (accum, phase) => [
        ...accum,
        ...Object.entries(phase.phaseData).map(
          entry => Object.values(entry[1])[0]
        )
      ],
      []
    );

  const fromAddress =
      projectDataArray[projectDataFieldNames.indexOf("location")],
    toAddress = projectDataArray[projectDataFieldNames.lastIndexOf("location")];

  const order = {
    clientId: projectData.clientId,
    order_date:
      projectDataFieldNames.includes("date") &&
      moment(
        projectDataArray[projectDataFieldNames.indexOf("date")].date
      ).format("YYYY/MM/DD"),
    from_address: {
      ...fromAddress,
      floor_number: (fromAddress && fromAddress.floorNumber) || 0,
      flat_number: (fromAddress && fromAddress.buildingName) || ""
    },
    to_address: {
      ...toAddress,
      floor_number: (toAddress && toAddress.floorNumber) || 0,
      flat_number: (toAddress && toAddress.buildingName) || ""
    },
    source_lift_availability: fromAddress
      ? fromAddress.liftAvailability || false
      : true,
    destination_lift_availability: toAddress
      ? toAddress.liftAvailability || false
      : true,
    invoicing_information: projectData.projectRefId,
    udf_fields: projectData.userDefinedFields
  };

  useEffect(() => {
    getProjectPhasesForProject();
  }, [projectId]);

  useEffect(() => {
    getProjectData();
  }, [projectId]); // Run hook once

  const isOpsUser = accessor.groups.some(group =>
    [
      "Developer Administrators",
      "Application Administrators",
      "Operations User of Pikkol Organization"
    ].includes(group)
  );

  const canAddTags = accessor.groups.every(
    group =>
      !["Client Users", "Operations User of Pikkol Organization"].includes(
        group
      )
  );
  const orderId = projectData ? projectData.operationalRefId : null;

  const isClientManagerOrAbove = accessor.groups.some(group =>
    [
      "Developer Administrators",
      "Application Administrators",
      "Client Admins",
      "Client Managers",
      "Account Managers of Pikkol Organization"
    ].includes(group)
  );

  return (
    <Grid className="mainContainer">
      <Header title={`Project ID: ${projectData.projectRefId || "Loading"}`} />
      <Grid container direction="column">
        <Grid container className={classes.section} direction="column">
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="flex-start"
            className={classes.projectTitle}>
            <Typography variant="h2">Project details</Typography>

            {isClientManagerOrAbove && projectData && (
              <Button
                color="primary"
                variant="contained"
                aria-haspopup="dialog"
                onClick={() => setDialogs({ ...dialogs, addProject: true })}>
                Add Phase
              </Button>
            )}
          </Grid>
          {loading.projectData ? (
            <Box width={640} height={160} m={2}>
              <Skeleton variant="circle" width={20} />
              <Skeleton variant="rect" width="60%" />
            </Box>
          ) : (
            <Grid container direction="column" style={{ width: "900px" }}>
              {accessor.clientId === null && (
                <RowGridFlexStart>
                  <Grid item className={classes.projectLabel}>
                    <Typography variant="body1">Client Name</Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="body1">
                      {projectData.clientName || ""}
                    </Typography>
                  </Grid>
                </RowGridFlexStart>
              )}
              <RowGridFlexStart>
                <Grid item className={classes.projectLabel}>
                  <Typography variant="body1">Created By: </Typography>
                </Grid>
                <Grid item className="project-page-data">
                  <Typography variant="body1">
                    {projectData.createdBy} at&nbsp;
                    {displayTime(projectData.createdAt)}
                  </Typography>
                </Grid>
              </RowGridFlexStart>
              {projectData.userDefinedFields &&
                Object.entries(projectData.userDefinedFields).map(
                  ([key, value]) => (
                    <RowGridFlexStart key={key}>
                      <Grid item className={classes.projectLabel}>
                        <Typography variant="body1">{key}</Typography>
                      </Grid>
                      <Grid item className="project-page-data">
                        <Typography variant="body1">{value}</Typography>
                      </Grid>
                    </RowGridFlexStart>
                  )
                )}
              <RowGridFlexStart>
                <Grid item className={classes.projectLabel}>
                  <Typography variant="body1">Tags</Typography>
                </Grid>
                <Grid item className="project-page-data">
                  {projectData.tags &&
                    projectData.tags.map((tag, index) => (
                      <Chip
                        label={tag}
                        key={index}
                        style={{ margin: "3px" }}
                        onDelete={() => {
                          updateProject(projectId, {
                            tags: projectData.tags.filter(
                              (tag, tagIndex) => tagIndex !== index
                            )
                          }).then(() => getProjectData());
                        }}
                      />
                    ))}
                </Grid>
                {newTag.add && (
                  <TextField
                    id="new-tag"
                    label="Add tag and press enter"
                    style={{ marginLeft: "20px" }}
                    value={newTag.value}
                    onChange={e => {
                      setNewTag({ ...newTag, value: e.target.value });
                    }}
                    onKeyPress={handleKeyPress}
                    InputProps={{
                      endAdornment: (
                        <IconButton onClick={handleAddTag} size="small">
                          <AddIcon />
                        </IconButton>
                      )
                    }}
                  />
                )}
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.addButton}
                  disabled={!canAddTags}
                  onClick={() => {
                    if (newTag.add && newTag.value.length > 0) handleAddTag();
                    else setNewTag({ ...newTag, add: !newTag.add });
                  }}
                  endIcon={
                    !newTag.add || newTag.value.length > 0 ? (
                      <AddIcon />
                    ) : (
                      <RemoveIcon />
                    )
                  }>
                  {!newTag.add || newTag.value.length > 0 ? `Add` : `Cancel`}
                </Button>
              </RowGridFlexStart>

              {isOpsUser && (
                <RowGridFlexStart>
                  <Grid item className={classes.projectLabel}>
                    <Typography variant="body1">Operational Ref Id</Typography>
                  </Grid>
                  <Grid item className="project-page-data">
                    <TextField
                      autoComplete="off"
                      label="Enter ops id here"
                      id="operational-ref-id"
                      onKeyPress={handleKeyPress}
                      value={projectData.operationalRefId || ""}
                      onChange={e =>
                        setProjectData({
                          ...projectData,
                          operationalRefId: e.target.value
                        })
                      }
                      disabled={!isOpsUser}
                      InputProps={{
                        endAdornment: (
                          <IconButton
                            size="small"
                            onClick={() =>
                              window.open(
                                `https://app.pikkol.com/admin/orders/${projectData.operationalRefId}`,
                                "_blank"
                              )
                            }>
                            <LinkIcon />
                          </IconButton>
                        )
                      }}
                    />
                    {!projectData.operationalRefId && isOpsUser && (
                      <Button
                        onClick={() =>
                          setDialogs({ ...dialogs, createOrder: true })
                        }>
                        GENERATE ORDER
                      </Button>
                    )}
                  </Grid>
                </RowGridFlexStart>
              )}
            </Grid>
          )}
        </Grid>
        <Grid container direction="column" className={classes.section}>
          <Typography variant="h2">Phases</Typography>
          <Grid container>
            {loading.phases ? (
              <Paper style={{ width: "320px", margin: "10px" }}>
                <Skeleton />
              </Paper>
            ) : (
              displayProjectPhases.map((displayprojectPhase, index) => (
                <Paper
                  className={classes.phaseCard}
                  elevation={2}
                  onClick={() =>
                    setDialogs({
                      ...dialogs,
                      updateProjectPhase: {
                        open: true,
                        projectPhase: projectPhases[index]
                      }
                    })
                  }
                  key={index}>
                  <Box
                    className={classes.statusBox}
                    style={{
                      backgroundColor: statusColors[displayprojectPhase.status]
                    }}>
                    <Typography variant="caption">
                      {statuses[displayprojectPhase.status]}
                    </Typography>
                  </Box>
                  <Typography variant="body2" className={classes.phaseName}>
                    <b>{displayprojectPhase.phaseName}</b>
                  </Typography>
                  {displayprojectPhase.phaseData.map((point, index) => {
                    return (
                      <Grid
                        container
                        className={classes.entries}
                        direction="column"
                        key={index}
                        style={{ margin: "7px 0px" }}>
                        {Object.entries(point).map(([key, value]) => (
                          <span key={key}>
                            <Typography variant="caption">{key}:</Typography>
                            <Typography variant="body1">
                              {value.nickname ||
                                value.date ||
                                value.string ||
                                value.number ||
                                value.name}
                            </Typography>
                          </span>
                        ))}
                      </Grid>
                    );
                  })}
                  <Grid
                    container
                    direction="row"
                    alignItems="flex-end"
                    justify="flex-end">
                    <Typography
                      variant="caption"
                      className={classes.createdTime}>
                      Created :
                      {displayTime(displayprojectPhase.phaseCreationTime)}
                    </Typography>
                  </Grid>
                </Paper>
              ))
            )}
          </Grid>
        </Grid>
        {Object.entries(projectData).length > 0 && (
          <CreateProjectPhaseDialog
            projectData={projectData}
            projectPhases={projectPhases}
            open={dialogs.addProject}
            onClose={e => {
              setDialogs({ ...dialogs, addProject: false });
              if (e === "phaseCreated") getProjectPhasesForProject();
            }}
          />
        )}
        {!projectData.operationalRefId && isOpsUser && (
          <CreateOrderDialog
            open={dialogs.createOrder}
            order={order}
            onClose={() => setDialogs({ ...dialogs, createOrder: false })}
            updateOrderId={orderId => {
              setProjectData({ ...projectData, operationalRefId: orderId });
              updateOpsRefId(orderId);
            }}
          />
        )}
        <StatusEditDialog
          open={dialogs.updateProjectPhase.open}
          clientId={projectData.clientId}
          projectPhase={dialogs.updateProjectPhase.projectPhase}
          onClose={event => {
            if (event) getProjectPhasesForProject();
            setDialogs({
              ...dialogs,
              updateProjectPhase: { open: false, projectPhase: {} }
            });
          }}
          refreshProjectPhase={async () => {
            await getProjectPhasesForProject();
          }}
        />
        {orderId && (
          <Grid container direction="column" className={classes.section}>
            <Grid container direction="column">
              <Typography variant="h2">Operational data</Typography>
              <PackingData
                orderId={+orderId}
                customerName={
                  (projectData.userDefinedFields &&
                    projectData.userDefinedFields["Customer Name"]) ||
                  ""
                }
                projectRefId={projectData.projectRefId}
                fromAddress={order.from_address.name}
                toAddress={order.to_address.name}
              />
              <TallyData orderId={+orderId} />
            </Grid>
          </Grid>
        )}
        <Grid container direction="column" className={classes.section}>
          <Files object="project" id={+projectId} opsRefId={orderId} />
        </Grid>
        <Grid container direction="column" className={classes.section}>
          <Comments object="project" id={+projectId} />
        </Grid>
      </Grid>
    </Grid>
  );
}

function RowGridFlexStart(props) {
  return (
    <Grid
      container
      direction="row"
      justify="flex-start"
      alignItems="center"
      style={{ marginTop: "14px" }}>
      {props.children}
    </Grid>
  );
}
