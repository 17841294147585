import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  Grid,
  Link,
  Checkbox,
  FormControlLabel,
  Typography
} from "@material-ui/core";

import { Header } from "../../components";
import Select from "react-select";
import { groupBy } from "lodash";

import ClientSelector from "../../components/ClientSelector";
import ClientLocationSelector from "../../components/ClientLocationSelector";
import CompletionWidget from "../../components/CompletionWidget";
import TallyWidget from "../../components/TallyWidget";
import MaterialDataTable from "../../components/MaterialDataTable";
import Scorecard from "../../components/Scorecard";

import * as moment from "moment";
import {
  getProjectPhases,
  getPhasesForClient,
  getPackingSummariesFromOrderIds,
  getTalliesFromOrderIds
} from "../../Api";
import CustomDatePicker from "../../components/CustomDatePicker";
import RadialChart from "../../components/RadialChart";

const statuses = {
  1: "Requested",
  2: "Accepted",
  4: "Started",
  8: "Completed"
};

export default function Dashboard(props) {
  const accessor = useSelector(state => state.accessor);
  const clientId = useSelector(state => state.clientId);

  const [serverCall, setServerCall] = useState(false);
  const [clientPhases, setClientPhases] = useState([]);
  const [summaryStats, setSummaryStats] = useState([]);
  const [scorecards, setScorecards] = useState([
    { name: "Number of Packages", value: 0 },
    {
      name: "Number of Assets packed correctly",
      value: 0
    }
  ]);

  const [filters, setFilters] = useState({
    date: moment().format("YYYY-MM-DD"),
    startDate: moment().subtract(1, "day"),
    endDate: moment().add(1, "day"),
    location: {},
    phaseId: null,
    createdByMe: false
  });
  const [data, setData] = useState([]);

  const getClientPhases = async () => {
    const searchClientId = accessor.clientId || clientId;
    if (searchClientId) {
      const { phases } = await getPhasesForClient(searchClientId);
      setClientPhases(phases);
    }
  };

  const refreshData = async () => {
    setSummaryStats([]);
    setScorecards([
      { name: "Number of Packages", value: 0 },
      {
        name: "Number of Assets packed correctly",
        value: 0
      }
    ]);
    setServerCall(true);
    const { phases } = await getProjectPhases({
      pageSize: 1000,
      pageNumber: 0,
      location: filters.location.nickname,
      startDate: filters.startDate.format("YYYY-MM-DD"),
      endDate: filters.endDate.format("YYYY-MM-DD"),
      phaseId: filters.phaseId,
      ...(filters.createdByMe ? { createdBy: accessor.id } : {}),
      ...(clientId ? { clientId } : {})
    });

    let phaseData = phases;
    const orderIds = phases
      .filter(ph => ph.opsRefId !== null)
      .map(ph => +ph.opsRefId);
    setData(phases);
    setServerCall(false);
    if (orderIds.length > 0) {
      const packingPromise = getPackingSummariesFromOrderIds(orderIds);
      const tallyPromise = getTalliesFromOrderIds(orderIds);
      Promise.all([packingPromise, tallyPromise]).then(
        ([
          {
            packing,
            firstTimeComplete,
            totalPackages,
            correctAssetsPacked,
            errorRate,
            assetFTC
          },
          { tallies }
        ]) => {
          const orderTallies = groupBy(tallies, "orderId");
          setSummaryStats([
            {
              name: "Asset FTC",
              description: "% assets which are correctly packed",
              value: assetFTC,
              color: "#41b800"
            },
            {
              name: "Project FTC",
              description: "# projects with FTC",
              value: firstTimeComplete,
              color: "#A1F4A5"
            },
            {
              name: "Error rate",
              description: "% projects with errors",
              value: errorRate,
              color: "#FF5252"
            }
          ]);
          setScorecards([
            { name: "Number of Packages", value: totalPackages },
            {
              name: "Number of Assets packed correctly",
              value: correctAssetsPacked
            }
          ]);
          phaseData = phases.map(phase => ({
            ...phase,
            summary: packing.find(row => row.order_id === +phase.opsRefId),
            tallies: orderTallies[phase.opsRefId]
          }));
          setData(phaseData);
        }
      );
    }
  };

  useEffect(() => {
    getClientPhases();
  }, [clientId]);

  useEffect(() => {
    if (filters.startDate && filters.endDate) refreshData();
    const reset10Minutes = setInterval(() => {
      if (filters.startDate && filters.endDate) refreshData();
    }, 600000);
    return () => clearInterval(reset10Minutes);
  }, [filters, clientId]);

  const phaseOptions = clientPhases.map(phase => ({
    value: phase.id,
    label: phase.name
  }));

  const columns = [
    { name: "projectId", label: "Project Id", options: { display: false } },
    { name: "projectRefId", label: "Project" },
    { name: "phaseName", label: "Phase" },
    { name: "summary", label: "Summary" },
    { name: "status", label: "Status" },
    { name: "tallies", label: "tallies", options: { display: false } }
  ];

  const customStyles = {
    container: styles => ({ ...styles, height: "40px" }),
    control: styles => ({ ...styles, height: "40px", borderColor: "#dbdbdb" }),
    menu: styles => ({ ...styles, width: "300px", zIndex: "1000" }),
    menuList: styles => ({ ...styles, width: "300px", zIndex: "1000" }),
    option: (styles, state) => ({
      ...styles,
      background: state.isSelected
        ? "#439fd9"
        : state.isFocused
        ? "#c7e9ff"
        : "#FFFFFF"
    })
  };

  return (
    <Grid className="mainContainer">
      <Header title="Dashboard" />

      <Grid
        container
        direction="row"
        justify="flex-start"
        spacing={2}
        alignItems="center">
        <Grid item>
          <CustomDatePicker
            disabled={serverCall}
            startDate={filters.startDate}
            endDate={filters.endDate}
            onChange={({ startDate, endDate }) => {
              setFilters({ ...filters, startDate, endDate });
            }}
          />
        </Grid>
        <Grid item>
          <ClientSelector isDisabled={serverCall} />
        </Grid>
        {clientId && (
          <Grid item>
            <Select
              className="dropdown"
              styles={customStyles}
              options={phaseOptions}
              isClearable={true}
              isDisabled={serverCall}
              onChange={e =>
                setFilters({ ...filters, phaseId: e ? e.value : null })
              }
              placeholder={clientId ? `Filter phase` : ` Select client First`}
            />
          </Grid>
        )}
        {clientId && (
          <Grid item>
            <ClientLocationSelector
              custom={false}
              isDisabled={serverCall}
              optional={true}
              label={clientId ? `Filter Location` : ` Select client First`}
              onChange={location => setFilters({ ...filters, location })}
            />
          </Grid>
        )}
        <Grid item>
          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                disabled={serverCall}
                checked={filters.createdByMe}
                onChange={e =>
                  setFilters({ ...filters, createdByMe: e.target.checked })
                }
              />
            }
            label={<Typography variant="body2">Created by me</Typography>}
          />
        </Grid>
      </Grid>
      <Grid container direction="row">
        <RadialChart
          width={300}
          height={200}
          className="metric-chart"
          metrics={serverCall ? [] : summaryStats}
        />
        {scorecards.map(card => (
          <Scorecard caption={card.name} value={card.value} />
        ))}
      </Grid>

      <MaterialDataTable
        data={data}
        columns={columns}
        busy={serverCall}
        pagination={{ block: true }}
        options={{
          customRowRender: (row, rowIndex) => (
            <tr
              key={rowIndex}
              className="table-row"
              onClick={e => {
                e.target.className.indexOf("projectRef") === -1 &&
                  window.open(`/project/${row.projectId}`, "_self");
              }}>
              <td align="center">
                <b>
                  <Link
                    href={`/project/${row.projectId}`}
                    className="projectRef">
                    <Typography variant="body2">{row.projectRefId}</Typography>
                  </Link>
                </b>
              </td>

              <td>{row.phaseName}</td>
              <td align="center">
                {row.phaseName === "Packing" ? (
                  <PackingData data={row.summary} />
                ) : (
                  <TallyData data={row.tallies} />
                )}
              </td>
              <td>{statuses[row.status]}</td>
            </tr>
          )
        }}
      />
      <div className="tooltip" style={{ display: "none" }} />
    </Grid>
  );
}

const PackingData = props =>
  props.data ? (
    <CompletionWidget
      completion={props.data.correct / props.data.planned}
      errorRate={props.data.wrong / props.data.planned || 0}
      className={`order-${props.data.order_id}`}
    />
  ) : (
    <Grid>
      <Typography variant="body1">There is no data</Typography>{" "}
    </Grid>
  );

const TallyData = props =>
  props.data ? (
    <TallyWidget
      tallies={props.data}
      className={`tally-${props.data[0].orderId}`}
    />
  ) : (
    <Grid>
      <Typography variant="body1">There is no tally data</Typography>
    </Grid>
  );
