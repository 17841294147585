import React from "react";
import PropTypes from "prop-types";
import {
  Grid,
  Select,
  MenuItem,
  IconButton,
  TextField,
  Typography
} from "@material-ui/core";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import LastPageIcon from "@material-ui/icons/LastPage";
import { Skeleton } from "@material-ui/lab";

function MaterialDataTable(props) {
  const {
    data = [],
    columns = [],
    pagination = {},
    options = {},
    busy = false,
    headerOptions = {},
    search = {}
    // serverSide = true
  } = props;

  const {
    page = 0,
    rowsPerPage = 10,
    rowsPerPageOptions = [],
    total = 1000,
    block = false
  } = pagination;

  const { customHeader, title } = headerOptions;
  // optional custom header function
  const { customRowRender } = options;
  // Optional custom render function

  const showColumns = columns.filter(
    column => !column.options || column.options.display !== false
  ); // Columns to be shown

  const isLastPage = page === ((total - 1) / rowsPerPage) << 0;
  // Is it last page, (Disable next page and last page buttons)

  return (
    <Grid>
      {title ? (
        <h1>{title}</h1>
      ) : (
        customHeader || (
          <Grid container direction="row">
            {search.enable && <TextField value={search.text} />}
          </Grid>
        )
      )}
      <table
        style={{
          width: "100%",
          margin: "5px",
          borderCollapse: "collapse"
        }}>
        <thead>
          <tr>
            {showColumns.map((column, i) => (
              <th style={{ padding: "10px" }} key={i}>
                {column.label}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {busy ? (
            <tr>
              <td colSpan={6}>
                <Skeleton />
              </td>
            </tr>
          ) : data.length === 0 ? (
            <tr>
              <td
                align="center"
                colSpan={6}
                style={{ border: "1px solid #ebebeb" }}>
                No data
              </td>
            </tr>
          ) : (
            data.map((row, i) => {
              return customRowRender ? (
                customRowRender(row, i)
              ) : (
                <tr key={i} style={{ margin: "8px" }}>
                  {showColumns.map(column => (
                    <td
                      style={{ padding: "16px" }}
                      align={
                        (column.options && column.options.align) || "center"
                      }>
                      {typeof row[column.name] === "object"
                        ? ""
                        : row[column.name]}
                    </td>
                  ))}
                </tr>
              );
            })
          )}
        </tbody>
      </table>
      {!block && (
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
          spacing={2}
          style={{ margin: "10px" }}>
          <Typography variant="body1">Rows</Typography>
          <Select
            style={{ margin: "0px 16px" }}
            value={rowsPerPage}
            onChange={e =>
              pagination.onChange({
                rowsPerPage: e.target.value,
                page: 0
              })
            }>
            {rowsPerPageOptions.map(option => (
              <MenuItem key={option} value={option}>
                <Typography variant="body1">{option}</Typography>
              </MenuItem>
            ))}
          </Select>
          <IconButton
            size="small"
            disabled={page === 0}
            onClick={() => pagination.onChange({ rowsPerPage, page: 0 })}>
            <FirstPageIcon />
          </IconButton>
          <IconButton
            size="small"
            style={{ margin: "0px 8px" }}
            disabled={page === 0}
            onClick={() =>
              pagination.onChange({ rowsPerPage, page: page - 1 })
            }>
            <ChevronLeftIcon />
          </IconButton>
          <Typography variant="body1">
            {rowsPerPage * page + 1}-
            {rowsPerPage * (page + 1) > total
              ? total
              : rowsPerPage * (page + 1)}{" "}
            of &nbsp;
            {total}
            &emsp; (Page {page + 1})
          </Typography>
          <IconButton
            size="small"
            disabled={isLastPage}
            style={{ margin: "0px 8px" }}
            onClick={() =>
              pagination.onChange({ rowsPerPage, page: page + 1 })
            }>
            <ChevronRightIcon />
          </IconButton>
          <IconButton
            size="small"
            disabled={isLastPage}
            onClick={() =>
              pagination.onChange({
                rowsPerPage,
                page: ~~((total - 1) / rowsPerPage)
              })
            }>
            <LastPageIcon />
          </IconButton>
        </Grid>
      )}
    </Grid>
  );
}

MaterialDataTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  columns: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.object),
    PropTypes.arrayOf(PropTypes.string)
  ]).isRequired,
  pagination: PropTypes.shape({
    page: PropTypes.number, // Currently on page
    rowsPerPage: PropTypes.number, // Selected number for rowsPerPage
    rowsPerPageOptions: PropTypes.arrayOf(PropTypes.number), // Options to show on pagination page size options
    total: PropTypes.number, // Total number of rows (to identify pagination total)
    block: PropTypes.bool // Block Pagination div
  }),
  options: PropTypes.shape({
    customRowRender: PropTypes.func
  }),
  busy: PropTypes.bool
};

export default MaterialDataTable;
