import { useEffect } from "react";
import PropTypes from "prop-types";

function useBlinkPageTitle(props) {
  const { prefix, title = document.title, suffix, duration } = props;
  let showPrefixOrSuffix = false;

  useEffect(() => {
    const toggleDialogTitle = setInterval(() => {
      showPrefixOrSuffix = !showPrefixOrSuffix; // Toggle show prefix or suffix
      document.title = `${showPrefixOrSuffix ? prefix : ``}${title || title}${
        showPrefixOrSuffix ? suffix : `` // edit title
      }`;
    }, duration);
    return () => {
      clearInterval(toggleDialogTitle);
      document.title = title; // Change the document title to original title, once unloaded
    };
  });
  return { title: document.title };
}

useBlinkPageTitle.PropTypes = {
  props: PropTypes.shape({
    prefix: PropTypes.string,
    title: PropTypes.string.isRequired,
    suffix: PropTypes.string,
    duration: PropTypes.number
  }).isRequired
};

export default useBlinkPageTitle;
