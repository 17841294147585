import React, { Fragment, useEffect, useState, useMemo } from "react";
import PropTypes from "prop-types";

import SVGLoading from "../SVGLoading";
import {
  Grid,
  Typography,
  Button,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import RefreshIcon from "@material-ui/icons/Refresh";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";

import {
  getTalliesFromOrderIds,
  getTallyData,
  reverseGeocode
} from "../../Api";

import { displayTime } from "../../constants";
const siteLabels = {
  source: "pickup",
  "in transit": "warehouse",
  destination: "drop"
};

const useStyles = makeStyles(theme => ({
  subsection: {
    marginTop: "23px"
  },
  packingRow: {
    marginBottom: "15px"
  },
  tallyCard: {
    width: 180,
    marginRight: 16,
    padding: "14px",
    cursor: "pointer",
    "&:hover": {
      color: theme.palette.primary.main
    }
  },
  tallyNumber: {
    marginTop: "10px",
    marginBottom: "23px"
  },
  createdTime: {
    fontStyle: "italic",
    color: "#A5a5a5"
  },
  tallyContainer: {
    marginTop: 23
  },
  noTallies: {}
}));

function TallyData(props) {
  const classes = useStyles();
  const { orderId } = props;
  const [busy, setBusy] = useState(false);
  const [tallyData, setTallyData] = useState({ tallies: [] });

  const [dialog, setDialog] = useState({
    open: false,
    tally: {},
    tallyDialogData: {}
  });

  const updateTallyDialog = async openTally => {
    const { orderPackages, tally, tallyPackages } = await getTallyData(
      openTally.id
    );

    setDialog({
      open: true,
      tally: tally,
      tallyDialogData: { orderPackages, tally, tallyPackages }
    });
  };

  const getTallyStatus = async () => {
    setBusy(true);
    setTallyData({
      tallies: orderId ? (await getTalliesFromOrderIds([orderId])).tallies : []
    });
  };

  const fetchLocationData = async () => {
    if (
      dialog &&
      dialog.tally.location &&
      dialog.tally.location.x &&
      dialog.tally.location.y
    ) {
      const { display_name } = await reverseGeocode({
        lat: dialog.tally.location.x,
        lon: dialog.tally.location.y
      });
      const words = display_name.split(",");
      setDialog({
        ...dialog,
        tally: {
          ...dialog.tally,
          locationWords: `${words[0]},${words[1] || ""}`
        }
      });
    }
  };

  useMemo(() => {
    fetchLocationData();
  }, [dialog.tally.location]);

  useEffect(() => {
    setBusy(false);
  }, [tallyData.tallies]);

  useEffect(() => {
    getTallyStatus();
  }, [props.orderId]);

  console.log(dialog);
  return busy ? (
    <Grid container justify="center" alignItems="center">
      <SVGLoading width="37px" height="37px" />
    </Grid>
  ) : (
    <Grid container>
      <Grid container direction="row" className={classes.subsection}>
        <Typography variant="h3">Tally data</Typography>
        <Button
          onClick={() => {
            getTallyStatus();
          }}
          size="small"
          style={{ margin: "0px 30px" }}
          startIcon={<RefreshIcon />}>
          Refresh
        </Button>
      </Grid>
      <Grid
        container
        direction="row"
        alignItems="center"
        className={classes.tallyContainer}>
        {tallyData.tallies.length === 0 ? (
          <Typography variant="body1" className={classes.noTallies}>
            No tallies created yet
          </Typography>
        ) : (
          tallyData.tallies.map((tally, i) => (
            <Fragment key={i}>
              {i !== 0 && <ChevronRightIcon style={{ marginRight: "12px" }} />}
              <Paper
                elevation={2}
                className={classes.tallyCard}
                onClick={() => {
                  updateTallyDialog(tally);
                }}>
                <Typography variant="h3">
                  {tally.stage} @ {siteLabels[tally.site]}
                </Typography>

                <Typography
                  variant="h2"
                  className={classes.tallyNumber}
                  style={{
                    color:
                      tally.committedPackages === tally.totalPackages
                        ? "#000"
                        : "#FF5252"
                  }}>
                  {tally.committedPackages} / {tally.totalPackages}
                </Typography>
                <Typography variant="caption" className={classes.createdTime}>
                  Created: {displayTime(tally.createdAt)}
                </Typography>
              </Paper>
            </Fragment>
          ))
        )}
      </Grid>
      <Dialog
        fullWidth
        open={dialog.open}
        onClose={() => setDialog({ open: false, tally: {} })}>
        <DialogTitle>Tally Data</DialogTitle>
        <DialogContent>
          <Typography variant="caption" className={classes.createdTime}>
            Created&emsp;:&emsp;{displayTime(dialog.tally.createdAt)}
          </Typography>
          <Typography variant="body1">
            Status{` `}&emsp;:&emsp;
            <span
              style={{
                color:
                  dialog.tally.committedPackages === dialog.tally.totalPackages
                    ? "#000000"
                    : "#FF5252"
              }}>
              {dialog.tally.committedPackages} /{`  `}
              {dialog.tally.totalPackages}
            </span>
          </Typography>
          {dialog.tally &&
            dialog.tally.location &&
            dialog.tally.location.x !== 0 &&
            dialog.tally.location.y !== 0 && (
              <Typography variant="body1">
                Location: (Lat,Lon) : {dialog.tally.location.x},
                {dialog.tally.location.y}
              </Typography>
            )}
          <Grid
            container
            justify="flex-start"
            alignItems="flex-start"
            style={{
              margin: "40px 0px",
              width: "90%"
            }}>
            {dialog.tallyDialogData &&
              dialog.tallyDialogData.orderPackages &&
              dialog.tallyDialogData.orderPackages.map(orderPackage => (
                <Grid
                  className={
                    dialog.tallyDialogData.tallyPackages.find(
                      scannedPackage =>
                        scannedPackage.packageSequenceNumber ===
                        orderPackage.packageSequenceNumber
                    )
                      ? "scanned"
                      : "notScanned"
                  }
                  key={orderPackage.id}
                  container
                  direction="row"
                  justify="center"
                  alignItems="flex-start"
                  style={{
                    width: "30px",
                    margin: "5px",
                    padding: "4px",
                    border: "1px solid black",
                    borderRadius: "2px"
                  }}>
                  {orderPackage.packageSequenceNumber}
                </Grid>
              ))}
          </Grid>
        </DialogContent>
      </Dialog>
    </Grid>
  );
}

TallyData.propTypes = {
  orderId: PropTypes.number.isRequired
};

export default TallyData;
